import {AircraftHttp} from "../../../http/AircraftHttp";

export default {

    async setAircraft({commit}) {
        const data = await AircraftHttp.getAllSortiesByQueryGroup('5ede65c68950cea54ca94293').then(result => result.data);
        commit('setAircraft', data);
    },

    setActiveAircraft({commit}, activeAircraft) {
        commit('setActiveAircraft', activeAircraft);
    },

}