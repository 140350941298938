import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onMenu }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},{ ...onMenu, ...onTooltip }),[_c(VIcon,{attrs:{"size":"25"}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Create")])])]}}])},[_c(VSheet,{attrs:{"rounded":""}},[_c(VList,{attrs:{"dense":"","tile":false,"nav":""}},[_c(VListItem,[_c(VListItemContent,[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Create")])])],1),_vm._l((_vm.scopedMenu),function(p,i){return _c(VListItem,{key:`item-${i}`,attrs:{"two-line":"","link":"","to":p.to,"disabled":_vm.isMenuItemDisabled(p.criteriaToCheck)}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(p.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(p.title)+" "+_vm._s(_vm.isMenuItemDisabled(p.criteriaToCheck) ? '(disabled)' : '')+" ")]),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(p.subtitle)}})],1)],1)}),(_vm.scopedAdminMenu.length > 0)?_c(VDivider):_vm._e(),_vm._l((_vm.scopedAdminMenu),function(p,i){return _c(VListItem,{key:`${i}`,attrs:{"two-line":"","link":"","to":p.to}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(p.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(p.title)+" ")]),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(p.subtitle)}})],1)],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }