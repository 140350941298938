<template>
  <v-sheet>
    <v-container class="">
      <v-row>
        <v-col cols="12" md="6">
          <h3 class="text-h3">Product Support</h3>
          <div class="text-h6 text-lg-h5 mt-3">Are you having trouble or experiencing difficulties? get in touch!</div>
          <div class="text-body-1">
            <div class="d-flex align-center mt-3">
              <v-icon>mdi-email-outline</v-icon>
              <span class="ml-1">
                support@myflyingmanager.aero
              </span>
            </div>
            <!--            <div class="d-flex align-center mt-3">-->
            <!--              <v-icon>mdi-phone-outline</v-icon>-->
            <!--              <span class="ml-1">-->
            <!--                +03 000 000 000 555 55-->
            <!--              </span>-->
            <!--            </div>-->
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <h3 class="text-h3">Contact Sales</h3>
          <div class="text-h6 text-lg-h5 mt-3">If you are interesting in finding out more about {{ config.product.name }} and how it might fulfill your organisation or groups needs, get in touch!
          </div>
          <div class="text-body-1">
            <div class="d-flex align-center mt-3">
              <v-icon>mdi-email-outline</v-icon>
              <span class="ml-1">
                enquiry@myflyingmanager.aero
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>


<script>
import config from '@/configs'

export default {
  name: "Contact",
  data() {
    return {
      config
    }
  }
}
</script>

<style scoped>

</style>