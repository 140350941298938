<template>
  <div class="my-5">
    <h1 class="subheading grey--text">Terms and Conditions</h1>
    <v-divider/>
    <v-container class="my-5">
      <div class="text-body-1">
        Welcome to our application. If you continue to browse and use this application, you are agreeing to comply with
        and be
        bound by the following terms and conditions of use, which together with our privacy policy govern
        {{ siteName }}'s
        relationship with you in relation to this application. If you disagree with any part of these terms and
        conditions,
        please do not use our application.
      </div>
      <div class="text-body-1 mt-2">
        The term '{{ siteName }}' or 'us' or 'we' refers to the owner of the application.
      </div>
      <div class="text-body-1 mb-2">
        The term 'you' refers to the user or viewer of our application.
      </div>
      <div class="text-body-1">
        The use of this application is subject to the following terms of use:
      </div>
      <div class="text-body-1">
        These terms include the privacy policy of {{ siteName }}, which can be viewed by clicking here.
        The content of the pages of this application is for your general information and use only. It is subject to
        change
        without notice.
      </div>
      <div class="text-body-1">
        Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance,
        completeness or suitability of the information and materials found or offered on this application for any
        particular
        purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly
        exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
        Your use of any information or materials on this application is entirely at your own risk, for which we shall
        not be
        liable. It shall be your own responsibility to ensure that any products, services or information available
        through this application meet your specific requirements.
      </div>
      <div class="text-body-1">
        This application contains material which is owned by or licensed to us. This material includes, but is not
        limited
        to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with
        the copyright notice, which forms part of these terms and conditions.
      </div>
      <div class="text-body-1">
        All trademarks reproduced in this application, which are not the property of, or licensed to the operator, are
        acknowledged on the application.
      </div>
      <div class="text-body-1">
        Unauthorised use of this application may give rise to a claim for damages and/or be a criminal offence.
        From time to time, this application may also include links to other websites. These links are provided for your
        convenience to provide further information. They do not signify that we endorse the application(s). We have no
        responsibility for the content of the linked application(s).
      </div>
      <div class="text-body-1">
        Your use of this application and any dispute arising out of such use of the application is subject to the laws
        of
        England.
      </div>
      <div class="text-body-1">
        We do our utmost to provide uninterrupted access to the application as much as possible. In the
        event that the application is temporarily unavailable (less than 48 hours) and a subscription for services
        rendered has been paid, no refunds will be
        provided for . In the event of a prolonged application interruption or downtime, a pro-rata refund will be
        provided on
        request subject to the following conditions:
      </div>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>1. The interruption/downtime lasts for more than 2 calendar days.</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>2. The interruption/downtime is not due to a natural disaster, an act of war, an act of
              terrorism, a health pandemic, restrictions imposed by a Government, an act of God.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>3. The refund amount due will be for each complete calendar day that the application is
              unavailable.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>4. The refund amount per day will be calculated by dividing the subscription amount by
              the number of days in the
              subscription period, after any applicable discounts attached to the user's subscription.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="h1">Content</div>

      <div class="text-body-1">
        You own all of the content and information you post on {{ siteName }}, and you can control how it is shared
        through your application settings.
      </div>
      <div class="text-body-1">
        For content that is covered by intellectual property rights, like photos and videos (IP content), you
        specifically give us the following permission, subject to your privacy settings: you grant us a
        non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any IP content that you post
        on or in connection with {{ siteName }} (IP License). This IP License ends when you delete your IP content or
        your
        account unless your content has been shared with others, and they have not deleted it.
      </div>
      <div class="text-body-1">
        When you delete IP content, it is deleted in a manner similar to emptying the recycle bin on a personal
        computer.
        However, you understand that removed content may persist in backup copies for a reasonable period of time (but
        will not be available to others).
      </div>
      <div class="text-body-1">
        We always appreciate your feedback or other suggestions about {{ siteName }}, but you understand that we may use
        them without any obligation to compensate you for them (just as you have no obligation to offer them).
      </div>
      <div class="text-body-1 my-2">
        Site Owner and Operator
      </div>
      <div class="text-body-1">
        This application is owned and operated by Logbook.aero:
      </div>
      <div class="text-body-1">
        20 Rippon Way, Southampton, SO31 8PA, United Kingdom
      </div>
      <div class="text-body-1">
        Email: hello@logbook.aero
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TermsView",
  data: () => ({
    siteName: `MyFlyingManager.aero`,
    billingEmailAddress: ""
  })
}
</script>

<style scoped>

</style>