<template>
  <div>
    <hero />
    <feature />
    <contact />
  </div>
</template>

<script>
import Hero from '@/components/landing/Hero.vue'
import Feature from "@/components/landing/Feature";
import Contact from '@/components/landing/Contact.vue'

export default {
  components: {
    Hero,
    Feature,
    Contact,
  }
}
</script>
