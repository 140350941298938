export class CalendarHttp {

    static async getCalendars(start,end) {
        try {
            return await window.axios.get(`calendar?start=${start}&end=${end}`).then(result => result.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }
    static async createEvent(event) {
        try {
            return await window.axios.post(`calendar/event`, event);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async updateEvent(event) {
        try {
            return await window.axios.put(`calendar/event`, event);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async deleteEvent(id) {
        try {
            return await window.axios.delete(`calendar/event/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }


}