import {SortieHttp} from "../../../http/SortieHttp";
import {LogbookHttp} from "@/http/LogbookHttp";

export default {
    async setSorties({commit}, groupDetails) {
        const data = await SortieHttp.getAllSortiesByQueryGroup(groupDetails._id, groupDetails.homeAirfieldGMTOffset).then(result => result.data);
        commit('setSorties', data);
    },

    async searchSortiesByQuery({commit}, searchQuery) {
        const data = await SortieHttp.searchSortiesByQuery(searchQuery).then(result => result.data);
        commit('setSorties', data);
    },

    setSortieSearchQueryParams({commit}, params) {
        commit('setSortieSearchQueryParams', params);
    },

    async setSortieStats({commit}, Id) {
        const data = await LogbookHttp.generateLogbookStats(Id).then(result => result.data);
        commit('setSortieStats', data);
    },
    async createSortie({commit}) {
        const data = await SortieHttp.createSortie(this.activeSortie).then(result => result.data);
        commit('setSorties', data);
    },
    async getSortie({commit}, Id) {
        const data = await SortieHttp.getSortie(Id).then(result => result.data);
        commit('setActiveSortie', data);
    },
    setActiveSortie({commit}, activeSortie) {
        commit('setActiveSortie', activeSortie);
    },
    setManageSortieDialog({commit}, status) {
        commit('setManageSortieDialog', status);
    },
    setManageViewSortieDialog({commit}, status) {
        commit('setManageViewSortieDialog', status);
    },
    setManageSortieBookInDialog({commit}, status) {
        commit('setManageSortieBookInDialog', status);
    },
    setManageCancelSortieDialog({commit}, status) {
        commit('setManageCancelSortieDialog', status);
    },
    setManageRecoverSortieDialog({commit}, status) {
        commit('setManageRecoverSortieDialog', status);
    },
    setManageDeleteSortieDialog({commit}, status) {
        commit('setManageDeleteSortieDialog', status)
    },
    setManageWalkOutDialog({commit}, status) {
        commit('setManageWalkOutDialog', status)
    },
    addSortie({commit}, activeSortie) {
        commit('addSortie', activeSortie);
    }
}