export default {

    setAnnouncements(state, announcements) {
        state.announcements = announcements;
    },

    setActiveAnnouncement(state, activeAnnouncement) {
        state.activeAnnouncement = activeAnnouncement;
    },
    setManageAnnouncementDialog(state, status) {
        state.manageAnnouncementDialog = status;
    },



}