<template>
  <v-app id="flying-club-manager">
    <toast/>
    <router-view/>
  </v-app>
</template>

<script>
import config from './configs'
import {mapActions} from "vuex";
import Toast from "@/components/shared/Toast";

export default {
  name: 'App',
  components: {Toast},
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  },
  metaInfo: {
    title: 'App',
    titleTemplate: '%s | My Flying Manager',
    htmlAttrs: {lang: 'en'},
    meta: [
      {charset: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
    ],
  },
  data: () => ({ drawer: false}),
  methods: {
    ...mapActions("group",["setGroups"]),
    ...mapActions("priorityLevels", ["setPostPriorityLevels"]),
    ...mapActions("roles", ["setAircrewRoles"]),
    ...mapActions("aircraft", ["setAircraft"]),
    ...mapActions("licence", ["setAllLicenceEndorsementTypes"]),
    ...mapActions("medicals", ["setMedicals"]),
  },
  async mounted() {
    await this.setGroups()
    await this.setPostPriorityLevels()
    await this.setAircrewRoles()
    await this.setAllLicenceEndorsementTypes()
    await this.setMedicals();
  }
};
</script>
