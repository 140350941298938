<template>
  <div class="Recover">
    <recover-password-view/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import RecoverPasswordView from "@/components/views/RecoverPasswordView";


export default {
  name: "Recover",
  components: {RecoverPasswordView},
  computed: {
    ...mapState("app",["loading"])
  }

}
</script>

<style scoped>
</style>