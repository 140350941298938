<template>
  <v-card class="elevation-12 justify-center" max-width="600px">
    <v-toolbar>
      <v-toolbar-title>Change Password</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-layout>
        <v-flex row wrap class="pa-3">
          <v-flex xs12>In order to <strong> protect your account</strong>, make sure your password:</v-flex>
        </v-flex>
      </v-layout>
      <v-list disabled flat dense>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="validPasswordLength ? 'success':'red'">
              mdi-{{ validPasswordLength ? 'check' : 'close' }}-circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>It contains at least 8 characters and at most 20 characters.</v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="hasAtLeastOneDigit ? 'success':'red'">
              mdi-{{ hasAtLeastOneDigit ? 'check' : 'close' }}-circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>It contains at least one digit.</v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="hasAtLeastOneUppercaseAlphabet ? 'success':'red'">
              mdi-{{ hasAtLeastOneUppercaseAlphabet ? 'check' : 'close' }}-circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>It contains at least one upper case alphabet.</v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="hasAtLeastOneLowercaseAlphabet ? 'success':'red'">
              mdi-{{ hasAtLeastOneLowercaseAlphabet ? 'check' : 'close' }}-circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>It contains at least one lower case alphabet.</v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="hasAtLeastOneSpecialCharacter ? 'success':'red'">
              mdi-{{ hasAtLeastOneSpecialCharacter ? 'check' : 'close' }}-circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>It contains at least one special character which includes !@$*()-+=^
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="hasNoInvalidSpecialCharacters ? 'success':'red'">
              mdi-{{ hasNoInvalidSpecialCharacters ? 'check' : 'close' }}-circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>It does not contain a special character which includes #&?
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="hasNoWhitespace ? 'success':'red'">mdi-{{ hasNoWhitespace ? 'check' : 'close' }}-circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>It doesn’t contain any white space.</v-list-item-content>
        </v-list-item>
      </v-list>
      <v-form>
        <v-text-field
            autocomplete="current-password"
            label="Enter new password"
            hint=""
            :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            required
            v-model="userPassword"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn :loading="isLoading" :disabled="isLoading || !isValidPassword" @click="changePassword" color="primary">
        Change password
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import {UserHttp} from "@/http/UserHttp";

export default {
  name: "ChangePassword",
  data() {
    return {
      userPassword: "",
      valid: true,
      value: true,
      isLoading: false
    }
  },
  computed: {
    ...mapState("app", ["loggedIn", "token", "loggedInUser"]),
    validPasswordLength() {
      let localRegex = /^.{8,20}$/;
      return localRegex.test(this.userPassword)
    },
    hasAtLeastOneDigit() {
      let localRegex = /^(?=.*[0-9])/;
      return localRegex.test(this.userPassword)
    },
    hasAtLeastOneLowercaseAlphabet() {
      let localRegex = /^(?=.*[a-z])/;
      return localRegex.test(this.userPassword)
    },
    hasAtLeastOneUppercaseAlphabet() {
      let localRegex = /^(?=.*[A-Z])/;
      return localRegex.test(this.userPassword)
    },
    hasAtLeastOneSpecialCharacter() {
      let localRegex = /^(?=.*[!@$^&-+=()])/;
      return localRegex.test(this.userPassword)
    },
    hasNoInvalidSpecialCharacters() {
      let localRegex = /^(?=.*[#&?])/;
      return !localRegex.test(this.userPassword)
    },
    hasNoWhitespace() {
      let localRegex = /\s/;
      return !localRegex.test(this.userPassword)
    },
    isValidPassword() {
      return (this.validPasswordLength &&
          this.hasAtLeastOneDigit &&
          this.hasAtLeastOneLowercaseAlphabet &&
          this.hasAtLeastOneUppercaseAlphabet &&
          this.hasAtLeastOneSpecialCharacter &&
          this.hasNoWhitespace && this.hasNoInvalidSpecialCharacters) ? true : false
    }
  },
  methods: {
    ...mapMutations("app", ["setToast", "setLoggedIn", "setToken", "setLoggedInUser"]),
    async changePassword() {
      if (this.isValidPassword && this.loggedInUser._id) {
        this.isLoading = true;
        try {
          await UserHttp.updatePassword(this.loggedInUser._id, this.userPassword);

          this.setToast({color: 'success', text: 'Password updated.', show: true});
          await this.$router.push({name: 'Dashboard'})

        } catch (e) {
          if (e.response) {
            this.setToast({color: 'error', text: e.response.data, show: true})
          } else {
            this.setToast({color: 'error', text: e.message, show: true})
          }
        } finally {
          this.isLoading = false;
        }
      } else {
        this.setToast({color: 'error', text: 'Validation failed', show: true})
      }
    }
  }
}
</script>

<style scoped>

</style>