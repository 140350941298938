<template>
  <login-form/>
</template>

<script>

import LoginForm from "@/components/shared/Login";

export default {
  name: "LoginView",
  components: {LoginForm},
}
</script>

<style scoped>

</style>