export class AnnouncementHttp {

    /**
     *
     * @param person
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async createNewAnnouncement(post) {
        try {
            return await window.axios.post(`announcement`, post);
        } catch (e) {
            return Promise.reject(e);
        }
    }


    /**
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAnnouncements(userId) {
        try {
            return await window.axios.get(`announcement/${userId}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     *
     * @param id
     * @param userid
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async setAsRead(id, userId) {
        try {
            return await window.axios.put(`announcement/read/${id}/${userId}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param id
     * @param userid
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async setAsAcknowledged(id, userid) {
        try {
            return await window.axios.put(`announcement/ack/${id}/${userid}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param announcement
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async updateAnnouncement(announcement) {
        try {
            return await window.axios.put(`announcement`, announcement);
        } catch (e) {
            return Promise.reject(e);
        }
    }


    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async deleteAnnouncement(id) {
        try {
            return await window.axios.delete(`announcement/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }


    static async react(id, person) {
        try {
            return await window.axios.put(`announcement/react/${id}`, person);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}