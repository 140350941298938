export default {
    setSorties(state, sorties) {
        state.sorties = sorties;
    },
    setSortieStats(state, sortieStats) {
        state.sortieStats = sortieStats;
    },

    setSortieSearchQueryParams(state, params) {
        state.sortieSearchQueryParams = params;
    },

    getSortie(state, sortie) {
        state.activeSortie = sortie;
    },
    setActiveSortie(state, activeSortie) {
        state.activeSortie = activeSortie;
    },
    setManageSortieDialog(state, status) {
        state.manageSortieDialog = status;
    },

    setManageViewSortieDialog(state, status) {
        state.manageViewSortieDialog = status;
    },

    setManageSortieBookInDialog(state, status) {
        state.manageSortieBookInDialog = status;
    },    
    setManageCancelSortieDialog(state, status) {
        state.manageCancelSortieDialog = status;
    },

    setManageRecoverSortieDialog(state, status) {
        state.manageRecoverSortieDialog = status;
    },
    setManageDeleteSortieDialog(state, status) {
      state.manageDeleteSortieDialog = status;
    },
    setManageWalkOutDialog(state, status) {
        state.manageWalkOutDialog = status;
    },
    addSortie(state, sortie) {
        return state.sorties.push(sortie);
    },

}