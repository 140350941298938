import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{attrs:{"color":"transparent"}},[_c(VContainer,{staticClass:"py-4 py-lg-4"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6","xl":"4"}},[_c(VSheet,{staticClass:"pa-2",attrs:{"elevation":"6","rounded":""}},[_c(VCarousel,{attrs:{"show-arrows":false,"cycle":"","height":"400","hide-delimiter-background":"","light":!_vm.$vuetify.theme.isDark},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.features),function(item,i){return _c(VCarouselItem,{key:i},[_c(VSheet,[_c(VResponsive,{staticClass:"mx-auto text-center",attrs:{"max-width":"360"}},[_c('div',{staticClass:"text-h5 mt-8"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text-body-1 mt-5"},[_vm._v(_vm._s(item.description))]),_c('div',{staticClass:"mt-4"},[_c('router-link',{staticClass:"text-decoration-none font-weight-bold text-h6",attrs:{"to":item.link.to}},[_vm._v(_vm._s(item.link.title))])],1)])],1)],1)}),1)],1)],1),_c(VCol,{attrs:{"cols":"12","lg":"6","offset-xl":"2","xl":"6"}},[_c('h2',{staticClass:"text-h3 text-lg-h2 mt-0 mt-lg-3 mt-xl-10"},[_vm._v("Simplify flying management!")]),_c('div',{staticClass:"text-h6 text-lg-h5 mt-6"},[_vm._v(_vm._s(_vm.config.product.name)+" allows members of your club, group, or syndicate to make on-line bookings for whatever resources are shared among the membership. The system is designed to be extensible and can be tailored to meet the demands of your group.")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }