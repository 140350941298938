import {PersonHttp} from "../../../http/PersonHttp";

export default {

    async setPersons({commit},groupId) {
        const data = await PersonHttp.getAllPersonsOfGroup(groupId).then(result => result.data);
        commit('setPersons', data);
    },
    async setActivePerson({commit}, id) {
        const data = await PersonHttp.getPersonsDetailsById(id).then(result => result.data);

        commit('setActivePerson', data);
    },
    async setLoggedInPerson({commit}) {
        const data = await PersonHttp.getLoggedInPerson().then(result => result.data);
        commit('setLoggedInPerson', data);
    },
    setManageViewPersonDialog({commit}, status) {
        commit('setManageViewPersonDialog', status);
    },
    setManageCreateNewMemberDialog({commit}, status) {
        commit('setManageCreateNewMemberDialog', status);
    },

    setManageChangeProfilePictureDialog({commit}, status) {
        commit('setManageChangeProfilePictureDialog', status);
    },

    setManageNewPersonDialog({commit}, status) {
        commit('setManageNewPersonDialog', status);
    },
}