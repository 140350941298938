
import {AnnouncementHttp} from "@/http/AnnouncementHttp";

export default {

    async setAnnouncements({commit}, params) {
        const data = await AnnouncementHttp.getAnnouncements(params.userId,params.groupId).then(result => result.data);
        commit('setAnnouncements', data);
    },

    setActiveAnnouncement({commit}, activeAnnouncement) {
        commit('setActiveAnnouncement', activeAnnouncement);
    },
    setManageAnnouncementDialog({commit}, status) {
        commit('setManageAnnouncementDialog', status);
    },


}