export default {

    setPosts(state, posts) {
        state.posts = posts;
    },

    setActivePost(state, activePost) {
        state.activePost = activePost;
    },
    setManagePostDialog(state, status) {
        state.managePostDialog = status;
    },



}