import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"offset-y":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onMenu }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},{ ...onMenu, ...onTooltip }),[_c(VBadge,{attrs:{"color":"success","dot":"","bordered":"","offset-x":"10","offset-y":"10"}},[_c(VAvatar,{attrs:{"size":"40"}},[(_vm.user.avatarImage)?_c('img',{attrs:{"src":`data:image/${_vm.user.avatarImage.contentType};base64,
                               ${_vm.user.avatarImage.data.toString('base64')}`}}):_c(VImg,{attrs:{"src":"/images/avatars/avatar1.svg"}})],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v("Account")])])]}}])},[_c(VList,{attrs:{"dense":"","nav":""}},[_vm._l((_vm.menu),function(item,index){return _c(VListItem,{key:index,attrs:{"exact":item.exact,"disabled":item.disabled,"link":"","to":item.to}},[_c(VListItemIcon,[_c(VIcon,{class:{ 'grey--text': item.disabled },attrs:{"small":""}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)],1)}),_c(VDivider,{staticClass:"my-1"}),_c(VListItem,{attrs:{"link":""},on:{"click":_vm.logOut}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-logout-variant")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Logout")])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }