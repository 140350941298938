import Vue from "vue";
import Router from "vue-router";
import store from "../store/store";

//Layouts
import DefaultLayout from "@/layouts/DefaultLayout";
import SimpleLayout from "@/layouts/SimpleLayout";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout";

//Pages
import StarterPage from "@/pages/StarterPage";
import PrivacyPage from "@/pages/PrivacyPage";
import TermsPage from "@/pages/TermsPage";
import Login from "@/views/Login";
import Recover from "@/views/Recover";
import Verify from "@/views/Verify";
import ChangePassword from "@/views/ChangePassword";

Vue.use(Router);

export const routes = [
  {
    path: "/",
    component: DefaultLayout,
    children: [

      {
        path: "",
        name: "starter-page",
        component: StarterPage,
        meta: { keepAlive: true }
      },
      {
        path: "/privacy",
        name: "Privacy Policy",
        component: PrivacyPage,
        meta: { keepAlive: true }
      },
      {
        path: "/terms",
        name: "Terms and Conditions",
        component: TermsPage,
        meta: { keepAlive: true }
      }
      // {
      //     path: '/sandbox',
      //     name: 'sandbox',
      //     component: () => import('@/pages/Sandbox'),
      // },
    ]
  },
  {
    path: "/",
    component: SimpleLayout,
    children: [
      {
        path: "/register",
        name: "register",
        component: () => import(/* webpackChunkName: "register-page" */ "@/pages/user/RegisterPage"),
        meta: { keepAlive: true }
      },
      {
        path: "/login",
        name: "login",
        component: Login,
        meta: { keepAlive: true }
      },
      {
        path: "/forgot",
        name: "recover",
        component: Recover,
        meta: { keepAlive: true }

      },
      {
        path: "/verify",
        name: "verify",
        component: Verify,
        meta: { keepAlive: true }
      },
      {
        path: "/user/change-password",
        name: "reset",
        component: ChangePassword,
        meta: { keepAlive: true }
      }
    ]
  },
  {
    path: "",
    component: AuthenticatedLayout,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "dashboard-page" */ "@/views/Dashboard"),
        redirect: {
          name: "My Dashboard"
        },
        meta: { requiresAuth: true },
        children: [
          {
            path: "",
            name: "My Dashboard",
            component: () => import(/* webpackChunkName: "my-dashboard-page" */ "@/pages/dashboard/MyDashboardPage"),
            meta: { requiresAuth: true }
          },
          {
            path: "met-dashboard",
            name: "Met Information",
            component: () => import(/* webpackChunkName: "met-dashboard-page" */ "@/pages/dashboard/MetDashboard"),
            meta: { requiresAuth: true }
          }]
      },
      {
        path: "/announcements",
        name: "Announcements",
        component: () => import(/* webpackChunkName: "announcements-page" */ "@/pages/announcements/AnnouncementsPage"),
        meta: { requiresAuth: true }
      },

      {
        path: "/p/:id",
        name: "Person",
        component: () => import(/* webpackChunkName: "person-page" */ "@/views/Person"),
        // meta: {requiresAuth: true},
        redirect: {
          name: "About"
        },
        children: [
          {
            path: "about",
            name: "About",
            component: () => import("@/pages/person/AboutPage"),
            redirect: {
              name: "Overview"
            },
            children: [
              {
                path: "overview",
                name: "Overview",
                component: () => import("@/pages/person/AboutOverviewPage")
              },
              {
                path: "ice_contact",
                name: "In Case of Emergency Contact Info",
                component: () => import("@/pages/person/ICEContactDetailsPage")
              },
              {
                path: "contact_and_basic_info",
                name: "Contact and Basic Info",
                component: () => import("@/pages/person/ContactAndBasicInfoPage")
              }
            ]
          },
          {
            path: "licence",
            name: "Licence",
            component: () => import("@/pages/licence/LicencePage")
          },
          {
            path: "licences",
            name: "Licences",
            component: () => import("@/pages/licence/LicencesPage")
          },
          {
            path: "medicals",
            name: "Medicals",
            component: () => import("@/pages/person/MedicalPage")
          },
          {
            path: "currency",
            name: "Other Non-Licence Qualifications and Currency",
            component: () => import("@/pages/person/CurrencyPage")
          },
          {
            path: "non-flying-qualifications",
            name: "Other Qualifications",
            component: () => import("@/pages/person/OtherQualificationsPage")
          },
          {
            path: "settings-and-preferences",
            name: "Settings and Preferences",
            component: () => import("@/pages/person/SettingsPage")
          }

        ]
      },
      {
        path: "/bookings",
        name: "Bookings",
        component: () => import(/* webpackChunkName: "bookings-page" */ "@/views/Bookings"),
        meta: { requiresAuth: true }
      },
      {
        path: "/calendar",
        name: "Calendar",
        component: () => import(/* webpackChunkName: "roster-page" */ "@/pages/calendar/CalendarPage"),
        meta: { requiresAuth: true }
      },
      {
        path: "/sorties",
        name: "Sortie",
        component: () => import(/* webpackChunkName: "sorties-page" */ "@/views/Sorties"),
        redirect: {
          name: "Flying Operations Dashboard"
        },
        meta: { requiresAuth: true },
        children: [
          {
            path: "flying-ops-dashboard",
            name: "Flying Operations Dashboard",
            component: () => import(/* webpackChunkName: "flying-ops-dashboard-page" */ "@/pages/sorties/FlyingOpsDashboard"),
            meta: { requiresAuth: true }
          },
          {
            path: "/sorties/entry",
            name: "Sortie Entry",
            component: () => import(/* webpackChunkName: "create-sortie-page" */ "@/pages/sorties/FlightEntryPage"),
            meta: { requiresAuth: true }
          },
          {
            path: "/sorties/entry/:id",
            name: "Sortie Entry",
            component: () => import(/* webpackChunkName: "create-sortie-page" */ "@/pages/sorties/FlightEntryPage"),
            meta: { requiresAuth: true }
          },
          {
            path: "upcoming",
            name: "Upcoming Sorties",
            component: () => import(/* webpackChunkName: "upcomingSorties-page" */ "@/pages/sorties/UpcomingSortiesPage"),
            meta: { requiresAuth: true }
          },
          {
            path: "logbook",
            name: "Logbook",
            component: () => import(/* webpackChunkName: "logbook-page" */ "@/pages/sorties/LogbookPage"),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: "/contacts",
        name: "Contacts",
        component: () => import(/* webpackChunkName: "contacts-page" */"@/pages/contacts/ContactsPage"),
        meta: { requiresAuth: true }

      },
      {
        path: "/logbook",
        name: "Logbook",
        component: () => import(/* webpackChunkName: "logbook-page" */ "@/views/Logbook"),
        meta: { requiresAuth: true }
      },
      {
        path: "/files",
        name: "Files",
        component: () => import(/* webpackChunkName: "my-dashboard-page" */ "@/pages/fileExplorer/FileManagerPage"),
        meta: { requiresAuth: true, keepAlive: true }
      },
      {
        path: "/groups",
        name: "Groups",
        component: () => import(/* webpackChunkName: "contacts-page" */"@/pages/groups/GroupsPage.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/administration",
        component: () => import(/* webpackChunkName: "admin-home-page" */ "@/views/Admin"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "",
            name: "Club Admin",
            component: () => import(/* webpackChunkName: "admin-home-page" */ "@/pages/admin/AccountPage"),
            meta: { requiresAuth: true }
          },
          {
            path: "announcements",
            name: "Announcements Administration",
            component: () => import(/* webpackChunkName: "announcements-administration-page" */ "@/views/AdminAnnouncements"),
            meta: { requiresAuth: true }
          },
          {
            path: "users",
            name: "Users",
            component: () => import(/* webpackChunkName: "user-administration-page" */ "@/pages/admin/UsersPage"),
            meta: { requiresAuth: true }
          },
          {
            path: "persons",
            name: "Persons",
            component: () => import(/* webpackChunkName: "user-administration-page" */ "@/pages/admin/PersonPage"),
            meta: { requiresAuth: true }
          },
          {
            path: "group",
            name: "Group Details",
            component: () => import(/* webpackChunkName: "group-administration-page" */ "@/pages/admin/GroupPage"),
            meta: { requiresAuth: true }
          },
          {
            path: "group-checks",
            name: "Group Checks",
            component: () => import(/* webpackChunkName: "group-administration-page" */ "@/pages/admin/GroupChecksPage"),
            meta: { requiresAuth: true }
          },
          {
            path: "aircraft",
            name: "Aircraft",
            component: () => import(/* webpackChunkName: "aircraft-administration-page" */ "@/pages/admin/AircraftPage"),
            meta: { requiresAuth: true }
          }
        ]
      }]
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "layout-error" */ "@/layouts/ErrorLayout"),
    children: [{
      path: "404",
      name: "error-404",
      component: () => import(/* webpackChunkName: "error-404" */ "@/pages/error/NotFoundPage")
    }, {
      path: "*",
      name: "error",
      component: () => import(/* webpackChunkName: "error" */ "@/pages/error/NotFoundPage")
    }]
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL || "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 10);
      });
    } else return { x: 0, y: 0 };
  },
  routes
});

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  store.state.app.loading = true;
  next();
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.app.loggedIn) {
      if (to.path !== "/login") {
        next({
          path: "/login",
          query: { redirect: to.fullPath }
        });
      }
      next();
    } else {
      next();
    }
  }
  next();
});

/**
 * After each route update
 */
router.afterEach(() => {
  store.state.app.loading = false;
});


export default router;