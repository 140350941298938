<template>
  <material-snackbar
      v-model="t.show"
      :color="t.color"
      :type="t.color"
      timeout="3000"
      bottom
      center
      :dismissible="false"
  >
    {{ t.text }}
  </material-snackbar>
</template>

<script>
    import {mapState} from 'vuex';
    import MaterialSnackbar from "@/components/shared/MaterialSnackbar";
    export default {
        name: "Toast",
      components: {MaterialSnackbar},
      computed: {
            ...mapState("app", ["toast"]),
            t: {
                get() {
                    return this.toast;
                }
            }
        }
    }
</script>