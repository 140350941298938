import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"py-6"},[_c(VResponsive,{staticClass:"mx-auto text-center",attrs:{"max-width":"700"}},[_c('div',{staticClass:"text-overline secondary--text mb-3"},[_vm._v(_vm._s(_vm.config.product.name))]),_c('h2',{staticClass:"text-h3 text-lg-h2 mb-6"},[_vm._v("Login")])]),_c(VCard,{staticClass:"pa-4 mx-auto",attrs:{"max-width":"600"}},[_c(VCardText,[_c(VTextField,{attrs:{"label":"Email","outlined":"","rules":[_vm.rules.required,_vm.rules.email]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"label":"Password","outlined":"","hide-details":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VBtn,{staticClass:"secondary",attrs:{"block":"","x-large":"","loading":_vm.isLoading,"disabled":_vm.isLoading || !_vm.formComplete,"rules":[_vm.rules.required]},on:{"click":_vm.doLogin}},[_vm._v("Sign In")]),_c('div',{staticClass:"text-center mt-2 text-body-2"},[_c('router-link',{attrs:{"to":"/forgot"}},[_vm._v("Forgot your password?")])],1)],1)],1),_c('div',{staticClass:"text-overline text-uppercase mt-3 text-center"},[_vm._v(" By signing in, you agree to the "),_c('a',{attrs:{"href":"/terms"}},[_vm._v("Terms of Service")]),_vm._v(" & "),_c('a',{attrs:{"href":"/privacy"}},[_vm._v("Privacy Policy")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }