export class SortieHttp {
    /**
     * Get all Sorties By Group
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAllSortiesByQueryGroup(id,gmtOffset) {
        try {
            return await window.axios.get(`sorties/group/${id}/${gmtOffset}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async getAllSortiesByPerson(id) {
        try {
            return await window.axios.get(`group/${id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async searchSortiesByQuery(query)  {
        try {
            return await window.axios.post(`sorties/search`, query);
        } catch (e) {
            return Promise.reject(e);
        }
    }



    /**
     *
     * @param sortie
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async createNewSortie(sortie) {
        try {
            return await window.axios.post(`sorties`, sortie);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param sortie
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getSortie(id) {
        try {
            return await window.axios.get(`sorties/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param sortie
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async updateSortie(sortie) {
        try {
            return await window.axios.put(`sorties`, sortie);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param sortie
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async bookIn(sortie) {
        try {
            return await window.axios.put(`sorties/bookin`, sortie);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async recoverSortie(id) {
        try {
            return await window.axios.put(`sorties/recover/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async cancelSortie(id) {
        try {
            return await window.axios.put(`sorties/cancel/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async deleteSortie(id) {
        try {
            return await window.axios.delete(`sorties/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async walkOut(id,sortie,sutto,etd) {
        try {
            return await window.axios.put(`sorties/walk/${id}/${sutto}/${etd}`, sortie);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}