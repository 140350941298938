import {PostPriorityLevelsHttp} from "@/http/PostPriorityLevelsHttp";

export default {
    /**
     *
     * @param commit
     */
    async setPostPriorityLevels({commit}) {
        const data = await PostPriorityLevelsHttp.getAll().then(result => result.data)
        commit('setPostPriorityLevels', data);
    },
}