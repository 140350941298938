import {CalendarHttp} from "@/http/CalendarHttp";

export default {

    async setCalendars({commit}, params) {
        const data = await CalendarHttp.getCalendars(params.start, params.end);
        commit('setEvents', data);
    },

    async createEvent(event) {
        await CalendarHttp.createEvent(event)
    },

    async updateEvent(event) {
        await CalendarHttp.updateEvent(event)
    },

    setActiveCalendarEvent({commit}, calendarEvent) {
        commit('setActiveCalendarEvent', calendarEvent);
    },
    setManageCalendarEventDialog({commit}, status) {
        commit('setManageCalendarEventDialog', status);
    },
    setManageCalendarFilterDialog({commit}, status) {
        commit('setManageCalendarFilterDialog', status);
    },
}