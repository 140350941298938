export class MedicalTypesHttp {
    /**
     * Get all Sorties By Group
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAll() {
        try {
            return await window.axios.get(`medicals/`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

}