<template>
  <div class="Privacy">
    <terms-view/>
  </div>
</template>

<script>
import TermsView from "@/components/views/TermsView";

export default {
  name: "Terms",
  components: {TermsView}
}
</script>

<style scoped>

</style>