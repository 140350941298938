export class AirportHttp {
    /**
     * Get all Sorties By Group
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAll() {
        try {
            return await window.axios.get(`airports/`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Search bookings
     * @param q
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async searchForAirportByQuery(q) {
        try {
            return await window.axios.get(`airports/search?q=${q}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static  async getSingleAirportByID(id) {
        try {
            return await window.axios.get(`airports/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }

}