export default {


    dataStore: {
        "$metadata": {
            "httpStatusCode": 200,
            "extendedRequestId": "M0fQ+QboAx3Je5loOiK1/bZyxRaELMlAP5D9p2NCtvqouw9PrUCHpklBxRGV1IHNGYsxCA8z2AQ=",
            "attempts": 1,
            "totalRetryDelay": 0
        },
        "Contents": [
            {
                "Key": "il_794xN.3333363755_srsg.jpg",
                "LastModified": "2021-11-26T17:17:10.000Z",
                "ETag": "\"2ea691fdc2b925060739b607241adb71\"",
                "Size": 163348,
                "StorageClass": "STANDARD"
            },
            {
                "Key": "test/",
                "LastModified": "2021-11-26T17:17:40.000Z",
                "ETag": "\"d41d8cd98f00b204e9800998ecf8427e\"",
                "Size": 0,
                "StorageClass": "STANDARD"
            },
            {
                "Key": "test/il_794xN.3333363653_kg6r.jpg",
                "LastModified": "2021-11-26T17:18:02.000Z",
                "ETag": "\"37eb47fd63ff05f134931e6a46dd1fcc\"",
                "Size": 79424,
                "StorageClass": "STANDARD"
            }
        ],
        "IsTruncated": false,
        "Marker": "",
        "MaxKeys": 1000,
        "Name": "myflyingmanager",
        "Prefix": ""
    }

}