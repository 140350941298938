export class BookingsHttp {
    /**
     * Get all Sorties By Group
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAllBookingsByQueryGroup(id) {
        try {
            return await window.axios.get(`bookings/group/${id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async getBookingsByGroupAndRange(groupId,start,end) {
        try {
            return await window.axios.get(`bookings/group/${groupId}/${start}/${end}`).then(result => result.data)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async getCurrentRoleHolder(type) {
        try {
            return await window.axios.get(`bookings/category/${type}`).then(result => result.data)
        } catch (err) {
            return Promise.reject(err);
        }
    }


    /**
     *
     * @param sortie
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async createNewBooking(booking) {
        try {
            return await window.axios.post(`bookings`, booking);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param sortie
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async updateBooking(booking) {
        try {
            return await window.axios.put(`bookings`, booking);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async deleteBooking(id) {
        try {
            return await window.axios.delete(`bookings/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}