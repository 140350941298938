export class LogbookHttp {
    static async generateLogbookPage(id,page) {
        try {
            return await window.axios.get(`logbook/${id}/${page}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async generateLogbookStats(id) {
        try {
            return await window.axios.get(`logbook/stats/${id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }
}