import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onMenu }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},{ ...onMenu, ...onTooltip }),[_c(VIcon,[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("External Links")])])]}}])},[_c(VSheet,[_c(VAlert,{staticClass:"mb-n3",attrs:{"type":"warning"}},[_vm._v("External Links visited at users own risk.")]),_vm._l((_vm.localItems),function(item,i){return _c(VList,{key:i,attrs:{"dense":""}},[(!item.url)?_c(VSubheader,[_vm._v(_vm._s(item.name))]):_vm._e(),(!item.url)?_c(VDivider):_c(VListItem,{on:{"click":function($event){return _vm.openLink(item.url);}}},[_c(VListItemTitle,[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"text caption"},[_vm._v(_vm._s(item.url))])]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-open-in-new")])],1)],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }