import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,[_c(VContainer,{},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"text-h3"},[_vm._v("Product Support")]),_c('div',{staticClass:"text-h6 text-lg-h5 mt-3"},[_vm._v("Are you having trouble or experiencing difficulties? get in touch!")]),_c('div',{staticClass:"text-body-1"},[_c('div',{staticClass:"d-flex align-center mt-3"},[_c(VIcon,[_vm._v("mdi-email-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v(" support@myflyingmanager.aero ")])],1)])]),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"text-h3"},[_vm._v("Contact Sales")]),_c('div',{staticClass:"text-h6 text-lg-h5 mt-3"},[_vm._v("If you are interesting in finding out more about "+_vm._s(_vm.config.product.name)+" and how it might fulfill your organisation or groups needs, get in touch! ")]),_c('div',{staticClass:"text-body-1"},[_c('div',{staticClass:"d-flex align-center mt-3"},[_c(VIcon,[_vm._v("mdi-email-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v(" enquiry@myflyingmanager.aero ")])],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }