export default {
    setFlyingStatus(state, flyingStatus) {
        state.flyingStatus = flyingStatus;
    },
    setAccountRoles(state, accountRoles) {
        state.accountRoles = accountRoles;
    },

    setGroupMembership(state, groupMembership) {
        state.groupMembership = groupMembership;
    },

    setUsersList(state, usersList) {
        state.usersList = usersList;
    }
}