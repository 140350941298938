export class LicenceHttp {

    static async createNewLicence(licenceData){
        try {
            return await window.axios.post(`licence`,licenceData)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async getSingleLicenceDetails(id) {
        try {
            return await window.axios.get(`licence/${id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async getAllLicencesOfPerson(personId) {
        try {
            return await window.axios.get(`licence/${personId}/all`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async updateLicence(id,licenceData) {
        try {
            return await window.axios.patch(`licence/${id}`,licenceData)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async removeLicence(id) {
        try {
            return await window.axios.delete(`licence/${id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async getAllLicenceEndorsementTypes() {
        try {
            return await window.axios.get(`licence/endorsements`)
        } catch (err) {
            return Promise.reject(err);
        }
    }
}