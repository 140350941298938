export default {
    setPersons(state, persons) {
        state.persons = persons;
    },
    setActivePerson(state, activePerson) {
        state.activePerson = activePerson;
    },
    setLoggedInPerson(state, loggedInPerson) {
        state.loggedInPerson = loggedInPerson;
    },
    setManageViewPersonDialog(state, status) {
        state.manageViewPersonDialog = status;
    },

    setManageCreateNewMemberDialog(state, status) {
        state.manageCreateNewMemberDialog = status;
    },

    setManageChangeProfilePictureDialog(state, status) {
        state.manageChangeProfilePictureDialog = status
    },

    setManageNewPersonDialog(state, status) {
        state.manageNewPersonDialog = status;
    },
}