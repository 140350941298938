import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"py-6"},[_c(VResponsive,{staticClass:"mx-auto text-center",attrs:{"max-width":"700"}},[_c('div',{staticClass:"text-overline secondary--text mb-3"},[_vm._v(_vm._s(_vm.config.product.name))]),_c('h2',{staticClass:"text-h3 text-lg-h2 mb-6"},[_vm._v("Forgot Password")])]),_c(VCard,{staticClass:"pa-4 mx-auto",attrs:{"max-width":"600"}},[_c(VCardText,[(_vm.formSubmit)?_c(VFlex,[_vm._v("If account exists, an email will be sent with further instructions.")]):_vm._e(),(!_vm.formSubmit)?_c(VFlex,[_vm._v("Don’t worry, happens to the best of us.")]):_vm._e(),(!_vm.formSubmit)?_c(VTextField,{attrs:{"label":"Email","outlined":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}):_vm._e(),(!_vm.formSubmit)?_c(VBtn,{staticClass:"secondary",attrs:{"block":"","x-large":"","loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":function($event){return _vm.sendRecoveryLink()}}},[_vm._v("email me a recovery link")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }