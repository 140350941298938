import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"4"}},[_c(VCard,{staticClass:"elevation-12 justify-center",attrs:{"max-width":"600px"}},[_c(VToolbar,[_c(VToolbarTitle,{staticClass:"pa-8"},[_vm._v("Reset Password")])],1),(!_vm.resetPasswordRequest)?_c(VCardText,[_c(VLayout,{staticClass:"pa-8",attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_vm._v("If you have forgotten your password, click "),_c('strong',[_vm._v("continue")]),_vm._v(".")]),_c(VFlex,{attrs:{"xs12":""}},[_vm._v("This will generate a new, temporary password for your account. After you have used this temporary password to log in, you can create your own password ")])],1),_c(VForm,[_c(VTextField,{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"readonly":"","value":this.$route.query.token}})],1)],1):_vm._e(),(_vm.resetPasswordRequest)?_c(VCardText,[_vm._v(" Your temporary password is: "),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.temporaryPassword))])],1),(_vm.canCopy)?_c(VListItemIcon,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.temporaryPassword)}}},[_c(VIcon,[_vm._v("mdi-content-copy")])],1)],1):_vm._e()],1),_vm._v(" Please copy the password and login, after which you will be asked to set a new password. ")],1):_vm._e(),_c(VCardActions,[_c(VSpacer),(!_vm.resetPasswordRequest)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.resetPassword()}}},[_vm._v("Continue ")]):_vm._e(),(_vm.resetPasswordRequest)?_c(VBtn,{attrs:{"to":"/","color":"primary"}},[_vm._v("Login ")]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }