<template>
  <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
  >
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn
              icon
              v-on="{ ...onMenu, ...onTooltip }">
            <v-icon size="25">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Create</span>
      </v-tooltip>
    </template>

    <v-sheet rounded>
      <v-list
          dense
          :tile="false"
          nav>
        <v-list-item>
          <v-list-item-content>
            <div class="text-h6 font-weight-bold">Create</div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line link :to="p.to" v-for="(p, i) in scopedMenu" :key="`item-${i}`"
                     :disabled="isMenuItemDisabled(p.criteriaToCheck)">
          <v-list-item-icon>
            <v-icon>{{ p.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ p.title }} {{ isMenuItemDisabled(p.criteriaToCheck) ? '(disabled)' : '' }}
            </v-list-item-title>
            <v-list-item-subtitle v-text="p.subtitle"/>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="scopedAdminMenu.length > 0"/>
        <v-list-item two-line link :to="p.to" v-for="(p, i) in scopedAdminMenu" :key="`${i}`">

          <v-list-item-icon>
            <v-icon>{{ p.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ p.title }}
            </v-list-item-title>
            <v-list-item-subtitle v-text="p.subtitle"/>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ToolbarCreate',
  props: {
    roles: {
      type: Array
    }
  },
  data() {
    return {
      list: [
        {
          title: 'Booking',
          subtitle: 'Book a group asset',
          icon: 'mdi-calendar',
          to: '/bookings',
          role: '*',
        },
        {
          title: 'Flights',
          subtitle: 'Notification of Flight',
          icon: 'mdi-airplane',
          to: '/sorties/entry',
          role: '*',
          criteriaToCheck: 'canFly'
        },
      ],
      adminList: [
        {
          title: 'Announcement',
          subtitle: 'Create announcement for the groups you have control of',
          icon: 'mdi-bullhorn',
          to: '/administration/announcements/announcement',
          role: 'manageAnnouncements'
        },
      ],
    }
  },
  computed: {
    ...mapState("user", ["flyingStatus"]),
    scopedMenu() {
      return this.list.filter(i => i.role == "*" || this.roles.includes(i.role) || this.roles.includes('rootAdmin'))
    },
    scopedAdminMenu() {
      return this.adminList.filter(i => i.role == "*" || this.roles.includes(i.role) || this.roles.includes('rootAdmin'))
    }
  },
  methods: {
    isMenuItemDisabled(criteria) {
      // If no Criteria to check, Menu Item is not Disabled
      return criteria in this.flyingStatus ? !this.flyingStatus[criteria] : false
    }
  }
}
</script>
