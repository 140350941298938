export default {
    showAsList: [{
        text: "Tentative Booking or Event",
        value: "tentative",
        color: "secondary",
        icon: "mdi-calendar-question"
    },
        {text: "Confirmed Booking or Event", value: "confirmed", color: "primary", icon: "mdi-calendar-alert"}],

    //Modals
    manageCalendarEventDialog: false,
    manageCalendarFilterDialog: false,
    //values
    activeCalendarEvent: null,
    events: []
}