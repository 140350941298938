<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">{{ config.product.name }}</div>
      <h2 class="text-h3 text-lg-h2 mb-6">Forgot Password</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-card-text>
        <v-flex v-if="formSubmit">If account exists, an email will be sent with further instructions.</v-flex>
        <v-flex v-if="!formSubmit">Don’t worry, happens to the best of us.</v-flex>
        <v-text-field label="Email" outlined v-model="username" v-if="!formSubmit"></v-text-field>

        <v-btn block class="secondary" x-large :loading="isLoading" :disabled="isLoading" v-if="!formSubmit" @click="sendRecoveryLink()">email me a recovery link</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import config from '@/configs'
import {mapMutations} from "vuex";
import {UserHttp} from "@/http/UserHttp";

export default {
name: "ForgotPassword",
  data() {
    return {
      config,
      username: '',
      formSubmit: false,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations("app", ["setToast", "setLoggedIn", "setToken", "setLoggedInUser"]),

    async sendRecoveryLink() {
      if (this.username.length > 0) {
        this.isLoading = true;
        try {
          await UserHttp.sendRecoveryLink(this.username)
          this.formSubmit = true
        }
        catch (e) {
          if (e.response) {
            this.setToast({color: 'error', text: e.response.data, show: true})
          } else {
            this.setToast({color: 'error', text: e.message, show: true})
          }
        } finally {
          this.isLoading = false;
        }

      }
    }
  }
}
</script>

<style scoped>

</style>