<template>
  <v-btn
      class="mx-1"
      :color="color"
      v-bind="$attrs"
      v-on="$listeners"
      :disabled="loading || disabled"
      :loading="loading"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'AppBtn',

  props: {
    disabled:{
      type: Boolean
    },
    loading:{
      type: Boolean
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>