<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-badge
              bordered
              :content="totalNotifications"
              offset-x="22"
              offset-y="50"
              :color="totalNotifications === 0 ? '':'error'"
          >
            <v-btn icon v-on="{ ...onMenu, ...onTooltip }">
              <v-icon v-if="totalNotifications === 0">mdi-bell-outline</v-icon>
              <v-icon v-else>mdi-bell-ring</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>Notifications</span>
      </v-tooltip>
    </template>
    <!-- dropdown card -->
    <v-card>
      <v-list three-line dense max-width="400">
        <v-subheader class="pa-2 font-weight-bold">Notifications</v-subheader>
        <v-divider/>
        <div v-if="totalNotifications > 0">
          <div v-for="(item, index) in notificationsList" :key="index">
            <v-divider v-if="index > 0 && index < totalNotifications" inset></v-divider>

            <v-list-item @click="$router.push({ name:`${item.link}`,params:{id:id}})" three-line>
              <v-list-item-avatar size="32" :color="item.color">
                <v-icon dark small>{{ item.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.group"></v-list-item-title>
                <v-list-item-subtitle class="caption" v-text="item.title"></v-list-item-subtitle>
                <v-list-item-subtitle  class="caption" v-text="item.subtitle"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="align-self-center">
                <v-list-item-action-text>
                  <v-btn small text>
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </div>
          <div>
            <app-btn small @click="refreshCanFlyStatus">Refresh
              <v-icon
                  right
                  dark
              >
                mdi-refresh
              </v-icon>
            </app-btn>
          </div>
        </div>
        <div v-else class="caption px-2">
          None
        </div>
      </v-list>
      <!--      <div class="text-center py-2">-->
      <!--        <v-btn small>See all</v-btn>-->
      <!--      </div>-->
    </v-card>
  </v-menu>
</template>

<script>
/*
|---------------------------------------------------------------------
| Toolbar Notifications Component
|---------------------------------------------------------------------
|
| Quickmenu to check out notifications
|
*/
// import {mapState} from "vuex";

import AppBtn from "@/components/app/Btn";

export default {
  name: 'ToolbarNotifications',
  components: {AppBtn},
  props: ['list','groups','id'],
  methods: {
    refreshCanFlyStatus() {
      this.$emit('refresh')
    }
  },
  computed: {
    totalNotifications(){
      return this.list.map(item => item.total).reduce((prev, next) => prev + next)
    },
    notificationsList(){
      let notificationsList = []
      for (const g of this.list) {
        notificationsList = [...notificationsList , ...g.notifications]
      }
      return notificationsList
    }
  }
}
</script>
