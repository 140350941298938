export class GroupHttp {

    /**
     * Returns a list of flying groups in the Database depending on status
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */

    static async getAll() {
        try {
            return await window.axios.get(`group`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async getSingle(id) {
        try {
            return await window.axios.get(`group/single/${id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async updateGroup(group) {
        try {
            return await window.axios.put(`group`,group)
        } catch (err) {
            return Promise.reject(err);
        }
    }


}