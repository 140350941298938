<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-4">
      <v-row>
        <v-col cols="12" lg="6" xl="4">
          <v-sheet elevation="6" class="pa-2" rounded>
            <v-carousel
                v-model="model"
                :show-arrows="false"
                cycle
                height="400"
                hide-delimiter-background
                :light="!$vuetify.theme.isDark"
            >
              <v-carousel-item v-for="(item, i) in features" :key="i">
                <v-sheet>
                  <v-responsive max-width="360" class="mx-auto text-center">
                    <div class="text-h5 mt-8">{{ item.title }}</div>
                    <div class="text-body-1 mt-5">{{ item.description }}</div>
                    <div class="mt-4">
                      <router-link :to="item.link.to" class="text-decoration-none font-weight-bold text-h6">{{ item.link.title }}</router-link>
                    </div>
                  </v-responsive>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="6" offset-xl="2" xl="6">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-lg-3 mt-xl-10">Simplify flying management!</h2>
          <div class="text-h6 text-lg-h5 mt-6">{{ config.product.name }} allows members of your club, group, or syndicate to make on-line bookings for whatever resources are shared among the membership. The system is designed to be extensible and can be tailored to meet the demands of your group.</div>
<!--          <v-btn color="primary" large class="mt-6">Explore demo</v-btn>-->
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import config from '@/configs'
export default {
  data() {
    return {
      config,
      model: 0,
      features: [{
        icon: 'mdi-tablet-cellphone',
        title: 'Multi-Platform Support',
        description: 'Your data is available across any device connected to the web.',
        link: {
          title: 'Learn More',
          to: '#'
        }
      }, {
        icon: 'mdi-file-chart',
        title: 'Detailed Reporting for group Managers',
        description: 'Complete with reports that support the management of your club, group or syndicate.',
        link: {
          title: 'Learn More',
          to: '#'
        }
      }, {
        icon: 'mdi-file-chart',
        title: 'Detailed Reporting for members',
        description: 'Complete with reports so that your members will will have the full picture of their flying history.',
        link: {
          title: 'Learn More',
          to: '#'
        }
      }, {
        icon: 'mdi-calendar',
        title: 'Track Licences & Ratings',
        description: 'Keep track of your member\'s licences, ratings and currencys',
        link: {
          title: 'Learn More',
          to: '#'
        }
      }]
    }
  }
}
</script>
