export default {
    setGroupChecks(state, groupChecks) {
        state.groupChecks = groupChecks;
    },

    setCurrencyVerifiersList(state, currencyVerifiersList) {
        state.currencyVerifiersList = currencyVerifiersList;
    },

    setActiveGroupCheck(state, groupCheck) {
        state.activeGroupCheck = groupCheck;
    },

    setManageGroupCheckDialog(state, status) {
        state.manageGroupCheckDialog = status;
    },

}