export class AircraftHttp {
    /**
     *
     * @param sortie
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async createNew(aircraft) {
        try {
            return await window.axios.post(`aircraft`, aircraft);
        } catch (e) {
            return Promise.reject(e);
        }
    }


    /**
     * Get all Sorties By Group
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAllSortiesByQueryGroup(id) {
        try {
            return await window.axios.get(`aircraft/group/${id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Search bookings
     * @param q
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async searchForAircraftByQuery(q) {
        try {
            return await window.axios.get(`aircraft/search?q=${q}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}