import Vue from 'vue'
import VueHead from 'vue-head'

/**
 * Manipulating the meta information of the head tag, a simple and easy way
 * https://github.com/ktquez/vue-head
 */
Vue.use(VueHead, {
    separator: '-',
    complement: 'VigiFlyer Software - Andrew Cross'
})
