<template>
  <div class="Login">
    <login-view />
  </div>
</template>

<script>
import {mapState} from "vuex";
import LoginView from "@/components/views/LoginView";


export default {
  name: "Login",
  components: {LoginView},
  computed: {
    ...mapState("app",["loading"])
  }
}
</script>

<style scoped>
.Login {
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
</style>