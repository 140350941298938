<template>
  <v-app>
    <v-layout>
      <v-app-bar app flat height="80" color="surface">
        <v-container fluid class="py-0 px-0 px-sm-2 fill-height">
          <router-link to="/" class="d-flex align-center text-decoration-none mr-2 toolbar3-min-width">
            <img src="/images/logo-small.png" alt="" height="40" class="mr-1">
            <span class="font-weight-black text-uppercase secondary--text d-none d-sm-block">
              {{ config.product.name }}
            </span>
          </router-link>

          <v-spacer></v-spacer>

          <div class="d-none d-md-block">
            <!-- menu-->
          </div>

          <v-spacer></v-spacer>

          <div class="toolbar-min-width text-right">
            <v-btn class="mr-2" color="secondary" large outlined to="/register">
              Register
            </v-btn>
            <v-btn color="primary" large to="/login">
              Log In
            </v-btn>
          </div>
        </v-container>
      </v-app-bar>

      <v-main class="surface">
        <v-container class="pt-3 mt-1 background">
          <router-view class="pa-2 fill-height"/>
          <v-spacer class="py-10"/>
          <Footer/>
        </v-container>

      </v-main>
    </v-layout>
  </v-app>
</template>

<script>
import config from '@/configs'
import Footer from "@/components/landing/Footer";

export default {
  name: 'DefaultLayout',
  components: {Footer},

  data() {
    return {
      config,
      drawer: null,
    }
  }
}
</script>
<style scoped>
.drawer-button {
  position: fixed;
  top: 64px;
  left: -22px;
  z-index: 6;
}

.toolbar-min-width {
  min-width: 130px;
}
</style>
