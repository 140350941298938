<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn
              icon
              v-on="{ ...onMenu, ...onTooltip }">
            <v-badge
                color="success"
                dot
                bordered
                offset-x="10"
                offset-y="10"
            >
              <v-avatar size="40">
                <img v-if="user.avatarImage" :src="`data:image/${user.avatarImage.contentType};base64,
                                 ${user.avatarImage.data.toString('base64')}`">
                <v-img v-else src="/images/avatars/avatar1.svg"></v-img>
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>
        <span>Account</span>
      </v-tooltip>
    </template>
    <!-- user menu list -->
    <v-list dense nav>
      <v-list-item
          v-for="(item, index) in menu"
          :key="index"
          :exact="item.exact"
          :disabled="item.disabled"
          link
          :to="item.to"
      >
        <v-list-item-icon>
          <v-icon small :class="{ 'grey--text': item.disabled }">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1"></v-divider>

      <v-list-item link @click="logOut">
        <v-list-item-icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
import {mapActions} from "vuex";

export default {
  name: 'ToolbarUser',
  props: {
    user: {
      type:Object,
    },
    person:{
      type:Object
    }
  },
  methods: {
    ...mapActions("app", ["setLoggedIn", "setLoggedInUser", "setToast"]),
    async logOut() {
      await this.setLoggedIn(false)
      await this.setLoggedInUser({})
      this.setToast({color: 'success', text: 'User successfully logged out of the application', show: true});
      await this.$router.push('/')
    }
  },
  data() {
    return {
      menu: [
        {icon: 'mdi-account-box-outline', text: 'Profile', to: `/p/${this.person._id}`},
        // { icon: 'mdi-cog-outline', text: 'Settings', to:'' },
        {icon: 'mdi-lock-reset', text: 'Change Password', to: '/user/change-password'},
        // { icon: 'mdi-chat-outline', text: 'Chat', to:'' }
      ]
    }
  }
}
</script>