export default {

    setActiveLicence(state, licenceDetails) {
        state.activeLicence = licenceDetails;
    },
    setLicences(state, licences) {
        state.licences = licences;
    },
    setAllLicenceEndorsementTypes(state, licenceEndorsementTypes) {
        state.licenceEndorsementTypes = licenceEndorsementTypes;
    },

}