export class PostHttp {

    /**
     *
     * @param person
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async createNewPost(post) {
        try {
            return await window.axios.post(`post`, post);
        } catch (e) {
            return Promise.reject(e);
        }
    }


    /**
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getPosts() {
        try {
            return await window.axios.get(`post`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async setAsRead(id) {
        try {
            return await window.axios.put(`post/read/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async setAsAcknowledged(id) {
        try {
            return await window.axios.put(`post/ack/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async react(id, person) {
        try {
            return await window.axios.put(`post/react/${id}`, person);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param announcement
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async updatePost(announcement) {
        try {
            return await window.axios.put(`post`, announcement);
        } catch (e) {
            return Promise.reject(e);
        }
    }


    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async deletePost(id) {
        try {
            return await window.axios.delete(`post/${id}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }


    static async react(id, person) {
        try {
            return await window.axios.put(`post/react/${id}`, person);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}