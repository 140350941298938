import {PersonHttp} from "@/http/PersonHttp";
import {UserHttp} from "@/http/UserHttp";

export default {
    async setFlyingStatus({commit}) {
        const data = await PersonHttp.getFlyingStatus().then(result => result.data)
        commit('setFlyingStatus', data);
    },
    async setAccountRoles({commit}, data) {
        commit('setAccountRoles', data);
    },

    async setGroupMembership({commit}, data) {
        commit('setGroupMembership', data);
    },


    async setUsersList({commit}) {
        const data = await UserHttp.getAccounts().then(result => result.data)
        commit('setUsersList', data);
    },

}