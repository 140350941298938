<template>
  <v-app>
<!--    <app-loading-status-dialog />-->
    <v-layout class="fill-height surface">
      <v-container :fluid="!isContentBoxed" class="pt-3 my-2">
        <v-row class="mb-5">
          <v-col cols="12">
            <app-authenticated-toolbar :is-fluid="true" :menu="scopedMenu" v-on:refresh="refreshCanFlyStatus" :person="loggedInPerson"/>
            <my-toolbar-progress-bar
                class="mt-9"
                :loading="this.loading"
                :color="this.loadingBarColor"
                slot="extension"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <router-view class="my-2 py-3"></router-view>
          </v-col>
        </v-row>
      </v-container>
      <v-footer app padless class="background mt-10">
        <div class="overline">
          © {{ new Date().getFullYear() }} — Andrew Cross, All Rights
          Reserved
        </div>
        <v-spacer></v-spacer>
        <div class="overline">

        </div>
      </v-footer>
    </v-layout>
  </v-app>
</template>

<script>
import config from '@/configs'
import {mapActions, mapState} from "vuex";
import AppAuthenticatedToolbar from "@/components/app/Toolbar";
import MyToolbarProgressBar from "@/components/widgets/MyToolbarProgressBar";

export default {
  name:"AuthenticatedLayout",
  components: {
    MyToolbarProgressBar,
    AppAuthenticatedToolbar,
  },
  data() {
    return {
      config,
      polling: null,
      drawer: null,
      mini: null,
      showSearch: false,
      isContentBoxed: true
    }
  },
  computed: {
    ...mapState("user", ["flyingStatus", "accountRoles"]),
    ...mapState("app", ["loggedInUser", "authenticatedLayoutTopMenu", "loading", "loadingBarColor"]),
    ...mapState("post", ['posts']),
    ...mapState("person",["loggedInPerson"]),

    scopedMenu() {
      return this.authenticatedLayoutTopMenu.filter(i => i.role == "*" || this.accountRoles.findIndex(r=> r.name === i.role)>-1)
    }

  },
  methods: {
    ...mapActions("group",["setGroups"]),
    ...mapActions("priorityLevels", ["setPostPriorityLevels"]),
    ...mapActions("roles", ["setAircrewRoles"]),
    ...mapActions("aircraft", ["setAircraft"]),
    ...mapActions("user", ["setAccountRoles", "setFlyingStatus", "setLoggedInUser"]),
    ...mapActions("person",["setPersons"]),
    ...mapActions("sorties", ["setSorties"]),
    ...mapActions("post", ["setPosts"]),
    ...mapActions("aircraft", ["setAircraft"]),


    async pollData() {
      await this.setPosts()
      await this.setFlyingStatus()
      this.polling = setInterval(async () => {
        if (this.loggedInUser._id) {
          await this.setFlyingStatus()
          await this.setPosts()
        }
      }, 10000);
    },
    async refreshCanFlyStatus() {
      await this.setFlyingStatus()
    }
  },
  async mounted() {
    // Initial load of Vuex data
    await this.pollData()
    // Setup list of items to fetch on a regular interval
  },
  beforeDestroy() {
    clearInterval(this.polling)
  },


}
</script>

<style scoped>
.v-toolbar__extension {
  padding: 0px !important;
}

.drawer-button {
  position: fixed;
  top: 60px;
  left: -22px;
  z-index: 6;
}
</style>