<template>
  <div class="ChangePassword">
    <change-password-view/>
  </div>
</template>

<script>
import ChangePasswordView from "@/components/views/ChangePasswordView";
import {mapState} from "vuex";

export default {
  name: "ChangePassword",
  components: {ChangePasswordView},
  computed: {
    ...mapState("app",["loading"])
  }
}
</script>

<style scoped>
</style>