<template>
  <div class="Verify">
    <verify-view/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import VerifyView from "@/components/views/VerifyView";


export default {
  name: "Verify",
  computed: {
    ...mapState("app",["loading"])
  },
  components: {VerifyView},
}
</script>

<style scoped>

</style>