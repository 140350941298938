import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VLayout,{staticClass:"fill-height surface"},[_c(VContainer,{staticClass:"pt-3 my-2",attrs:{"fluid":!_vm.isContentBoxed}},[_c(VRow,{staticClass:"mb-5"},[_c(VCol,{attrs:{"cols":"12"}},[_c('app-authenticated-toolbar',{attrs:{"is-fluid":true,"menu":_vm.scopedMenu,"person":_vm.loggedInPerson},on:{"refresh":_vm.refreshCanFlyStatus}}),_c('my-toolbar-progress-bar',{staticClass:"mt-9",attrs:{"slot":"extension","loading":this.loading,"color":this.loadingBarColor},slot:"extension"})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('router-view',{staticClass:"my-2 py-3"})],1)],1)],1),_c(VFooter,{staticClass:"background mt-10",attrs:{"app":"","padless":""}},[_c('div',{staticClass:"overline"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" — Andrew Cross, All Rights Reserved ")]),_c(VSpacer),_c('div',{staticClass:"overline"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }