import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VLayout,[_c(VAppBar,{attrs:{"app":"","flat":"","height":"80","color":"surface"}},[_c(VContainer,{staticClass:"py-0 px-0 px-sm-2 fill-height",attrs:{"fluid":""}},[_c('router-link',{staticClass:"d-flex align-center text-decoration-none mr-2 toolbar3-min-width",attrs:{"to":"/"}},[_c('img',{staticClass:"mr-1",attrs:{"src":"/images/logo-small.png","alt":"","height":"40"}}),_c('span',{staticClass:"font-weight-black text-uppercase secondary--text d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.config.product.name)+" ")])]),_c(VSpacer),_c('div',{staticClass:"d-none d-md-block"}),_c(VSpacer),_c('div',{staticClass:"toolbar-min-width text-right"},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"secondary","large":"","outlined":"","to":"/register"}},[_vm._v(" Register ")]),_c(VBtn,{attrs:{"color":"primary","large":"","to":"/login"}},[_vm._v(" Log In ")])],1)],1)],1),_c(VMain,{staticClass:"surface"},[_c(VContainer,{staticClass:"pt-3 mt-1 background"},[_c('router-view',{staticClass:"pa-2 fill-height"}),_c(VSpacer,{staticClass:"py-10"}),_c('Footer')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }