import {BookingsHttp} from "../../../http/BookingsHttp";

export default {
    /**
     *
     * @param commit
     */
    async setBookings({commit},query) {
        const data = await BookingsHttp.getBookingsByGroupAndRange(query.groupId, query.start, query.end);
        commit('setBookings', data);
    },
    setActiveBooking({commit}, activeBooking) {
        commit('setActiveBooking', activeBooking);
    },
    setManageBookingDialog({commit}, status) {
        commit('setManageBookingDialog', status);
    },

    setManageDeleteBookingDialog({commit}, status) {
        commit('setManageDeleteBookingDialog', status);
    },
}