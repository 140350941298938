<template>
  <forgot-password/>
</template>

<script>

import ForgotPassword from "@/components/shared/ForgotPassword";

export default {
  name: "RecoverPasswordView",
  components: {ForgotPassword}
}
</script>

<style scoped>

</style>