export class UserHttp {

    /**
     *
     * @param person
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async createAccount(user) {
        try {
            return await window.axios.post(`user`, user);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param username
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async sendRecoveryLink(username) {
        try {
            return await window.axios.post(`user/recover/${username}`).then(result => result.data);
        } catch (e) {
            return await Promise.reject(e);
        }
    }

    /**
     * get list of all accounts in DB
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAccounts() {
        try {
            return await window.axios.get(`user/all`)
        } catch (e) {
            return await Promise.reject(e);
        }
    }

    /**
     *
     * @param token
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAccount(id) {
        try {
            return await window.axios.post(`user/${id}`).then(result => result.data);
        } catch (e) {
            return await Promise.reject(e);
        }
    }

    /**
     *
     * @param token
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAccountRoles(id) {
        try {

            return await window.axios.get(`user/${id}/roles`);
        } catch (e) {
            return await Promise.reject(e);
        }
    }

    static async changeAccountStatus(id,sendEmail=false){
        return await window.axios.put(`user/${id}/account/status/${sendEmail}`);
    }

    /**
     *
     * @param token
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getTemporaryPassword(token) {
        try {
            return await window.axios.post(`user/reset/${token}`).then(result => result.data);
        } catch (e) {
            return await Promise.reject(e);
        }
    }

    /**
     *
     * @param id
     * @param password
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async updatePassword(id, password) {
        try {
            return await window.axios.post(`user/update-password/${id}/${password}`).then(result => result.data);
        } catch (e) {
            return await Promise.reject(e);
        }
    }
}