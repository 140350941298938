import {AirportHttp} from "../../../http/AirportHttp";

export default {
    /**
     *
     * @param commit
     */
    async setAirports({commit}) {
        const data = await AirportHttp.getAll().then(result => result.data);
        commit('setAirports', data);
    }
}