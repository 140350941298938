import {GroupChecksHttp} from "@/http/GroupChecksHttp";

export default {

    /**
     *
     * @param commit
     */
    async setGroupChecks({commit}) {
        const data = await GroupChecksHttp.getAll().then(result => result.data);
        commit('setGroupChecks', data);
    },

    async setCurrencyVerifiersList({commit},groupId) {
        const data = await GroupChecksHttp.getCurrencyVerifiersList(groupId).then(result => result.data);

        commit('setCurrencyVerifiersList', data);
    },

    setActiveGroupCheck({commit}, groupCheck) {
        commit('setActiveGroupCheck', groupCheck);
    },

    setManageGroupCheckDialog({commit}, status) {
        commit('setManageGroupCheckDialog', status);
    },



}