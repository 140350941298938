export class AuthHttp {

    /**
     * Login the user
     * @param username
     * @param password
     * @returns {Promise<void>}
     */
    static async login(username, password) {
        try {
            return await window.axios.post(`auth/login`, {}, { auth: { username, password }
            }).then(result => result.data);
        } catch (e) {
            return await Promise.reject(e);
        }
    }

    static async updateToken() {
        try {
            return await window.axios.post(`auth/update-token`).then(result => result.data);
        } catch (e) {
            return await Promise.reject(e);
        }
    }

}