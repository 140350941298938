import {GroupSessionsHttp} from "../../../http/GroupSessionsHttp";
import { GroupHttp } from "@/http/GroupHttp";

export default {

  async setSessionStatus({commit},groupIds) {
    const data = await GroupSessionsHttp.getCurrentSessionStatus(groupIds).then(result => result.data)
    commit('setSessionStatus', data);
  },




}