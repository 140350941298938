export default {
    activeBooking: {},
    bookings: [],
    bookingCategories: [
        { text: "G-BUUI", value: "G-BUUI", timed: true, color:"#6666ff" },
        { text: "G-PVCV", value: "G-PVCV", timed: true, color:"#6666ff" },
        { text: "Duty Officer", value: "Duty Officer", timed: false, color:"#000000" },
        { text: "IER Responder", value: "IER Responder", timed: false, color:"#d5411f" },
        { text: "Airfield Fire Cover", value: "Airfield Fire Cover", timed: false, color:"#d5411f" }
    ],
    bookingListFilters:[],
    manageBookingDialog: false,
    manageDeleteBookingDialog: false
}