import configs from '@/configs'

const {product, theme} = configs
const {isRTL, globalTheme} = theme

export default {
    product,

    // app theme dark or light
    globalTheme,

    // themes and layout configurations
    isRTL,

    loading: false,
    loadingBarColor: '#000000',

    manageAppLoadingStatusDialog: true,

    drawer: null,
    drawerImage: true,
    mini: false,
    landingSiteNavigationItems: [
        {
            title: 'Home',
            icon: 'mdi-home',
            to: '/'
        },
        {
            title: 'Features',
            icon: '',
            to: '#'
        },
        {
            title: 'Sign In',
            icon: 'mdi-login',
            to: '/login'
        },
    ],
    toast: {
        color: 'success',
        text: 'Success',
        show: false
    },
    loggedIn: false,
    token: '',
    loggedInUser: {},

    authenticatedLayoutTopMenu: [
        {
            key: 'menu.dashboard',
            icon: 'mdi-view-dashboard',
            text: 'Dashboard',
            link: '/dashboard',
            tooltip: 'View your Dashboard',
            role: '*'
        },
        {
            key: 'menu.announcements',
            icon: 'mdi-bullhorn',
            text: 'Announcements',
            link: '/announcements',
            tooltip: 'View announcements connected to the group',
            role: '*'
        },
        {
            key: 'menu.bookings',
            icon: 'mdi-calendar',
            text: 'Bookings',
            link: '/bookings',
            tooltip: 'Manage booking of Aircraft and Duty Officer Roster',
            role: '*'
        },
        {
            key: 'menu.calendar',
            icon: 'mdi-calendar',
            text: 'Calendar',
            link: '/calendar',
            tooltip: 'Calendar',
            role: '*'
        },
        {
            key: 'menu.sorties',
            icon: 'mdi-airplane',
            text: 'Flights',
            link: '/sorties',
            tooltip: 'View FlyPro and manage Flights',
            role: '*'
        },
        // {
        //     key: 'menu.contacts',
        //     icon: 'mdi-contacts-outline',
        //     text: 'Contacts',
        //     link: '/contacts',
        //     tooltip: 'View Member\'s contact details',
        //     role: '*'
        // },
        {
            key: 'menu.groups',
            icon: 'mdi-contacts-outline',
            text: 'Groups',
            link: '/groups',
            tooltip: 'Groups',
            role: '*'
        },
        // {
        //     key: 'menu.docs',
        //     icon: 'mdi-library-shelves',
        //     text: 'Docs',
        //     link: '/library',
        //     tooltip: 'Document Library',
        //     role: '*'
        // },
        // {
        //     key: 'menu.docs',
        //     icon: 'mdi-library-shelves',
        //     text: 'Files',
        //     link: '/files',
        //     tooltip: 'File Explorer',
        //     role: '*'
        // },
        {
            key: 'menu.admin',
            icon: 'mdi-cog-outline',
            text: 'Admin',
            link: '/administration/',
            tooltip: 'Admin Pages',
            role: 'admin'
        },
    ],

    sortiePageSidebarMenuItems: [
        {
            key: 'menu.flypro',
            icon: 'mdi-airplane',
            text: 'Flying Programme',
            link: '/sorties/flying-ops-dashboard',
            role: '*',
            canFlyCriteriaApplied: false
        },
        {
            key: 'menu.current-sorties',
            icon: 'mdi-timer-sand',
            text: 'My Upcoming Flights',
            link: '/sorties/upcoming',
            role: '*',
            canFlyCriteriaApplied: false
        },
        {
            key: 'menu.past-sorties',
            icon: 'mdi-book-open-variant',
            text: 'My Logbook',
            link: '/sorties/logbook',
            role: '*',
            canFlyCriteriaApplied: false
        },
    ],

    dashboardPageSidebarMenuItems: [
        {
            key: 'menu.my-dashboard',
            icon: 'mdi-view-dashboard',
            text: 'My Dashboard',
            link: '/dashboard',
            role: '*'
        },
        {
            key: 'menu.met-dashboard',
            icon: 'mdi-weather-sunny',
            text: 'Met Information',
            link: '/dashboard/met-dashboard',
            role: '*'
        },
    ],

    adminPageSidebarMenuItems: [
        {
            key: 'menu.aircraft',
            icon: 'mdi-airplane',
            text: 'Aircraft',
            link: '/administration/aircraft',
            role: 'manageAircraft',
            canFlyCriteriaApplied: false
        },
        {
            key: 'menu.announcements',
            icon: 'mdi-bullhorn',
            text: 'Announcements',
            link: 'announcements',
            role: 'manageAnnouncements',
            canFlyCriteriaApplied: false
        },
        {
            key: 'menu.group',
            icon: 'mdi-domain',
            text: 'Group Details',
            link: 'group',
            role: 'manageGroup',
            canFlyCriteriaApplied: false
        },
        // {
        //     key: 'menu.persons',
        //     icon: 'mdi-account-multiple',
        //     text: 'Persons',
        //     link: '/administration/persons',
        //     role: 'managePersons',
        //     canFlyCriteriaApplied: false
        // },
        {
            key: 'menu.group-checks',
            icon: 'mdi-domain',
            text: 'Group Checks',
            link: 'group-checks',
            role: 'manageGroup',
            canFlyCriteriaApplied: false
        },
        {
            key: 'menu.users',
            icon: 'mdi-account-multiple',
            text: 'Users',
            link: '/administration/users',
            role: 'manageUserAccounts',
            canFlyCriteriaApplied: false
        },
    ]

}