export default {
    dark: false,
    drawer: {
        image: 0,
        gradient: 0,
        mini: false,
    },
    gradients: [
        'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
        'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
        'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
    ],
    accountRoles:[],
    notifications: [],
    rtl: false,
    flyingStatus:[],
    groupMembership:[],
    usersList:[]
}