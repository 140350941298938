export default {

    activePerson: null,
    persons: [],
    loggedInPerson:null,

    manageViewPersonDialog :false,
    manageCreateNewMemberDialog:false,
    manageChangeProfilePictureDialog:false,
    manageNewPersonDialog:false,

}