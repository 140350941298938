<template>
    <div>
        {{currentUTCTime}} Z
    </div>
</template>

<script>
    export default {
        name: "CurrentTime",
        data() {
            return{
                currentTime: '',
                currentUTCTime:''

            }
        },
        computed: {
        },
        mounted() {
            let now = new Date()
            this.currentTime = now.toTimeString().split(' ')[0]
            this.currentUTCTime = now.toUTCString().split(' ')[4]

            setInterval(() => {
              let now = new Date()
                this.currentTime = now.toTimeString().split(' ')[0]
              this.currentUTCTime = now.toUTCString().split(' ')[4]
            },1000);
        }
    }
</script>

<style scoped>

</style>