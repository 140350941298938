export default {

    activeSortie: null,
    manageSortieDialog: false,
    manageSortieBookInDialog: false,
    manageViewSortieDialog: false,
    manageCancelSortieDialog: false,
    manageRecoverSortieDialog: false,
    manageDeleteSortieDialog: false,
    manageWalkOutDialog: false,
    sorties: [],
    sortieStats: [],
    sortieSearchQueryParams: {
        groupIds: [],
        startDate: "",
        endDate: ""
    },

    availableActions : [
        {action: 'view', validFlightStatus: ['*']},
        {action: 'edit', validFlightStatus: ['scheduled', 'delayed departure']},
        {action: 'delete', validFlightStatus: ['scheduled', 'delayed departure', 'cancelled']},
        {action: 'walk out', validFlightStatus: ['scheduled', 'delayed departure']},
        {
            action: 'cancel',
            validFlightStatus: ['walking to aircraft', 'start up and taxi', 'airborne', 'awaiting flight details', 'late']
        },
        {action: 'recover', validFlightStatus: ['cancelled']},
        {action: 'book in', validFlightStatus: ['airborne', 'awaiting flight details', 'late']}]

}