export default {
    setBookings(state, bookings) {
        state.bookings = bookings;
    },
    setActiveBooking(state, activeBooking) {
        state.activeBooking = activeBooking;
    },
    setManageBookingDialog(state, status) {
        state.manageBookingDialog = status;
    },
    setManageDeleteBookingDialog(state, status) {
        state.manageDeleteBookingDialog = status;
    },
}