<template>
  <div>
    <h1>My Flying Manager Privacy Policy</h1>

    <div class="text-body-1">This privacy policy will explain how our
      organisation uses the personal data we collect from you when you use our website.
    </div>
    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>What data do we collect?</h2></v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="text-body-1">My Flying Manager collects the following data:</div>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Personal identification information (Name, email address, phone number, etc.)
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Details
                of your membership status
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-airplane</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Details relating to asset bookings for groups that you are a member of.
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-airplane</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Details relating to flying sorties that you organised, managed, or as part of the
                crew onboard in connection with your membership of a connected flying
                organisation
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>How do we collect your data?</h2></v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="text-body-1">You directly provide My Flying Manager with most of the data we collect. We collect
            data and process data
            when you:
          </div>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Register online or place an order for any of our products or services.
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-message</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Voluntarily complete a customer survey or provide feedback on any of our message
                boards or via email.
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cookie</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Use or view our website via your browser's cookies.</v-list-item-content>
            </v-list-item>
          </v-list>

          <div class="text-body-1">My Flying Manager may also receive your data indirectly from the following sources:
          </div>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Organisations
                that use our service to allow you to interact with their membership activities such as booking assets.
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>How will we use your data?</h2></v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="text-body-1">My Flying Manager collects your data so that we can:</div>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-airplane</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Allow the capture of flying sorties in connection with your membership of a flying
                organisation that has an agreement with us
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-icon>mdi-account</v-icon>
              <v-list-item-content>Manage your account.</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-icon>mdi-email</v-icon>
              <v-list-item-content>Email you with service status and feature updates</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>How do we store your data?</h2></v-expansion-panel-header>
        <v-expansion-panel-content>

          <div class="text-body-1">My Flying Manager securely stores your data at
            <v-btn flat text href="https://www.mongodb.com/cloud/atlas">
              <v-icon>mdi-database</v-icon>
              MongoDB Atlas
            </v-btn>
            . The
            data is access using a secure password and privilege level access to the data is limited to the site
            administrator. Information is provided to the application via a secure usernane and password on the server
            side.
          </div>

          <div class="text-body-1">My Flying Manager will keep your data for as long as you are a member of the one of
            the groups that use our
            service or 7 years. Once this time period has expired, we will delete your data by running a scheduled job
            to remove your personal details from the database.
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>Marketing</h2></v-expansion-panel-header>
        <v-expansion-panel-content>

          <div class="text-body-1">My Flying Manager does not engage in marketing our services to individuals who use
            this service as part of
            their membership of a flying Organisation.
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>What are your data protection rights?</h2></v-expansion-panel-header>
        <v-expansion-panel-content>

          <div class="text-body-1">My Flying Manager would like to make sure you are fully aware of all of your data
            protection rights. Every
            user is entitled to the following:
          </div>

          <div class="text-body-1"><b>The right to access</b>&nbsp;- You have the right to request My Flying Manager for
            copies of your
            personal data. We may charge you a small fee for this service.
          </div>

          <div class="text-body-1"><b>The right to rectification</b>&nbsp;- You have the right to request that My Flying
            Manager correct any
            information you believe is inaccurate. You also have the right to request My Flying Manager to complete the
            information you believe is incomplete.
          </div>

          <div class="text-body-1"><b>The right to erasure</b>&nbsp;- You have the right to request that My Flying
            Manager erase your personal
            data, under certain conditions.
          </div>

          <div class="text-body-1"><b>The right to restrict processing</b>&nbsp;- You have the right to request that My
            Flying Manager
            restrict the processing of your personal data, under certain conditions.
          </div>

          <div class="text-body-1"><b>The right to object to processing</b>&nbsp;- You have the right to object to My
            Flying Manager's
            processing of your personal data, under certain conditions.
          </div>

          <div class="text-body-1"><b>The right to data portability</b>&nbsp;- You have the right to request that My
            Flying Manager transfer
            the data that we have collected to another organization, or directly to you, under certain conditions.
          </div>

          <div class="text-body-1">If you make a request, we have one month to respond to you. If you would like to
            exercise any of these
            rights, please contact us at our email: <a href="mailto:webmaster@myflyingmanager.aero">webmaster@myflyingmanager.aero</a>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>What are cookies?</h2></v-expansion-panel-header>
        <v-expansion-panel-content>

          <div class="text-body-1">Cookies are text files placed on your computer to collect standard Internet log
            information and visitor
            behaviour information. When you visit our websites, we may collect information from you automatically
            through cookies or similar technology
          </div>

          <div class="text-body-1">For further information, visit <a href="allaboutcookies.org">allaboutcookies.org</a>.
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>How do we use cookies?</h2></v-expansion-panel-header>
        <v-expansion-panel-content>

          <div class="text-body-1">My Flying Manager uses cookies in a range of ways to improve your experience on our
            website, including:
          </div>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Keeping you signed in</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-google-analytics</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Understanding how you use our website</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>What types of cookies do we use?</h2></v-expansion-panel-header>
        <v-expansion-panel-content>

          <div class="text-body-1">There are a number of different types of cookies, however, our website uses:</div>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-monitor-cellphone-star</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Functionality - My Flying Manager uses these cookies so that we recognise you on our
                website and remember your previously selected preferences. These could include what language you prefer
                and location you are in. A mix of first-party and third-party cookies are used.
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-google-analytics</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Advertising - My Flying Manager uses these cookies to collect information about your
                visit to our website, the content you viewed, the links you followed and information about your browser,
                device, and your IP address. My Flying Manager sometimes shares some limited aspects of this data with
                third parties for advertising purposes. We may also share online data collected
                through cookies with our advertising partners. This means that when you visit another website, you may
                be shown advertising based on your browsing patterns on our website.
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>How to manage your cookies</h2></v-expansion-panel-header>
        <v-expansion-panel-content>

          <div class="text-body-1">You can set your browser not to accept cookies, and <a
              href="http://allaboutcookies.org" target="_blank">allaboutcookies.org</a> tells you how
            to remove cookies from
            your browser. However, in a few cases, some of our website features may not function as a result.
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>Privacy policies of other websites</h2></v-expansion-panel-header>
        <v-expansion-panel-content>

          <div class="text-body-1">The My Flying Manager contains links to other websites. Our privacy policy applies
            only to our website, so
            if you click on a link to another website, you should read their privacy policy.
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>Changes to our privacy policy</h2></v-expansion-panel-header>
        <v-expansion-panel-content>

          <div class="text-body-1">My Flying Manager keeps its privacy policy under regular review and places any
            updates on this web page.
            This privacy policy was last updated on 24 March 2021.
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>How to contact us?</h2></v-expansion-panel-header>
        <v-expansion-panel-content>
          <h2>How to contact us</h2>

          <div class="text-body-1">If you have any questions about My Flying Manager's privacy policy, the data we hold
            on you, or you would
            like to exercise one of your data protection rights, please do not hesitate to contact us.
          </div>

          <div class="text-body-1">Email us at: <a href="mailto:webmaster@myflyingmanager.aero">webmaster@myflyingmanager.aero</a>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h2>How to contact the appropriate authorities</h2></v-expansion-panel-header>
        <v-expansion-panel-content>

          <div class="text-body-1">Should you wish to report a complaint or if you feel that My Flying Manager has not
            addressed your concern
            in a satisfactory manner, you may contact the Information Commissioner's Office.
          </div>

          <div class="text-body-1">Website: <a href="https://ico.org.uk/">https://ico.org.uk/</a></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

export default {}
</script>
