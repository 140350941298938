<template>
  <div class="mb-5">
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense nav>
        <v-subheader class="text-uppercase font-weight-bold text-subtitle-1">{{ config.product.name }}</v-subheader>
        <v-subheader class="text-uppercase font-weight-bold">Menu</v-subheader>
        <v-divider/>
        <v-list-item v-for="(item, index) in menu" :key="index" :to="item.link">
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">

        </div>
      </template>
    </v-navigation-drawer>

    <v-btn
        class="d-md-none drawer-button"
        rounded
        @click="drawer = !drawer"
    >
      <v-icon right>mdi-menu</v-icon>
    </v-btn>

    <v-app-bar app flat :height="$vuetify.breakpoint.mdAndUp ? '110' : '60'" color="background">
      <div class="d-flex flex-column flex-grow-1 fill-height">
        <v-container class="px-0 py-0">
          <div class="px-sm-2 py-1 body-2 d-flex align-center justify-space-between">
            <div>
              <router-link to="#" class="d-flex align-center text-decoration-none mr-2">
                <img src="/images/logo-small.png" alt="" height="40" class="mr-1">
                <span class="font-weight-black text-uppercase secondary--text d-none d-sm-block">
              {{ config.product.name }}
            </span>
              </router-link>
            </div>
            <v-spacer/>
            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <current-time />
            </div>
            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <toolbar-create :roles="this.accountRoles"/>
            </div>
            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <toolbar-notifications :list="flyingStatus" :groups="groups" :id="person._id" v-on:refresh="refreshCanFlyStatus"/>
            </div>
            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <external-site-links-widget :items="externalSitesArray" />
            </div>
            <toolbar-user :user="this.loggedInUser" :person="person"/>
          </div>
        </v-container>
        <v-container class="py-1 px-0 px-sm-2 d-flex flex-grow-1 flex-column text-center justify-center align-center">

          <div class="d-none d-md-block">
              <v-tooltip bottom v-for="(item, index) in filteredMenu" :key="index">
                <template #activator="{ on: onTooltip }">
                  <v-btn
                      :to="item.link"
                      text
                      class="mx-1"
                      v-on="{ ...onTooltip }"
                  >
                    {{ item.text }}
                  </v-btn>
                </template>
                <span> {{ item.tooltip }}</span>
              </v-tooltip>
          </div>
        </v-container>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import config from '@/configs'
import {mapState} from "vuex";
import ToolbarNotifications from "@/components/widgets/ToolbarNotifications";
import ToolbarCreate from "@/components/widgets/ToolbarCreate";
import ToolbarUser from "@/components/widgets/ToolbarUser";
import CurrentTime from "@/components/shared/CurrentTime";
import ExternalSiteLinksWidget from "@/components/widgets/ExternalSiteLinksWidget";

export default {
  name: "AppAuthenticatedToolbar",
  components: {ExternalSiteLinksWidget, CurrentTime, ToolbarUser, ToolbarCreate, ToolbarNotifications},
  props: {
    isFluid: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Array,
    },
    person:{
      type:Object
    }
  },
  data() {
    return {
      config,
      drawer: null,
    }
  },
  computed: {
    ...mapState("user", ["flyingStatus","groupMembership", "accountRoles"]),
    ...mapState("app", ["loggedInUser"]),
    ...mapState("group", ["groups","activeGroup"]),

    loggedInUserGroupMembershipsIds(){
      return this.groupMembership.map(g => g._id)
    },

    filteredMenu() {
      if (!this.isMemberOfBFC) {
       return this.menu.filter(i=> i.text !=="Bookings")
      }
      return this.menu
    },


    isMemberOfBFC() {
      return this.loggedInUserGroupMembershipsIds.includes(`5ede65c68950cea54ca94293`)
    },
    externalSitesArray(){
      let externalSitesArray = []
      const scopedGroups = this.groups.filter(g => this.loggedInUserGroupMembershipsIds.includes(g._id))

      scopedGroups.forEach(g => {
        externalSitesArray.push({ name: g.name})
        g.externalUsefulLinksList.forEach(link => externalSitesArray.push({ name: link.name, url: link.url, active:link.active}))})

      return externalSitesArray
    }

  },
  methods:{
    refreshCanFlyStatus() {
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 60px;
  left: -22px;
  z-index: 6;
}
</style>