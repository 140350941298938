export default {

    medicals: [],

    medicalTypes:[],

    // Ref AMC2 MED.B.001 Limitations to medical certificates
    medicalCertificateLimitations: [
        {id:1, code:'TML', summary:'Time limitation', description:''},
        {id:1, code:'VDL', summary:'Wear corrective lenses and carry a spare set of spectacles', description:''},
        {id:1, code:'VML', summary:'Wear multifocal spectacles and carry a spare set of spectacles', description:''},
        {id:1, code:'VNL', summary:'Have available corrective spectacles and carry a spare set of spectacles', description:''},
        {id:1, code:'CCL', summary:'Wear contact lenses that correct for defective distant vision', description:''},
        {id:1, code:'VCL', summary:'Valid by day only', description:''},
        {id:1, code:'RXO', summary:'Specialist ophthalmological examination(s) ', description:''},
        {id:1, code:'SIC', summary:'Specific regular medical examination(s)   contact the medical assessor of the licensing authority', description:''},
        {id:1, code:'HAL', summary:'Wear hearing aid(s)', description:''},
        {id:1, code:'APL', summary:'Valid only with approved prosthesis', description:''},
        {id:1, code:'AHL', summary:'Valid only with approved hand controls', description:''},
        {id:1, code:'OML', summary:'Valid only as or with a qualified co-pilot', description:''},
        {id:1, code:'OCL', summary:'Valid only as a qualified co-pilot', description:''},
        {id:1, code:'OSL', summary:'Valid only with a safety pilot and in aircraft with dual controls', description:''},
        {id:1, code:'OPL', summary:'Valid only without passengers', description:''},
        {id:1, code:'ORL', summary:'Valid only with a safety pilot if passengers are carried and in aircraft with dual controls', description:''},
        {id:1, code:'OAL', summary:'Restricted to demonstrated aircraft type', description:''},
        {id:1, code:'SSL', summary:'Special restriction(s) as specified', description:''},

    ]
}