export class GroupChecksHttp {

    static async createNewGroupCheck(groupCheck) {
        try {
            return await window.axios.post(`groupchecks`, groupCheck);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async getAll() {
        try {
            return await window.axios.get(`groupchecks/`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async getCurrencyVerifiersList(groupId) {
        try {
            return await window.axios.get(`groupchecks/${groupId}/verifiers`)
        } catch (err) {
            return Promise.reject(err);
        }
    }


    static async changeActiveStatus(groupCheck) {
        try {
            return await window.axios.put(`groupchecks/status`, groupCheck)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async updateOne(groupCheck) {
        try {
            return await window.axios.put(`groupchecks/`, groupCheck)
        } catch (err) {
            return Promise.reject(err);
        }
    }


    static async deleteOne(id) {
        try {
            return await window.axios.delete(`groupchecks/${id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

}