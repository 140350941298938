import {PostHttp} from "@/http/PostHttp";

export default {

    async setPosts({commit}) {
        const data = await PostHttp.getPosts().then(result => result.data);
        commit('setPosts', data);
    },

    setActivePost({commit}, activePost) {
        commit('setActivePost', activePost);
    },
    setManagePostDialog({commit}, status) {
        commit('setManagePostDialog', status);
    },


}