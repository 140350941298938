import Vuetify from '@/plugins/vuetify'

export default {

    /**
     * Theme and Layout
     */
    setGlobalTheme: (state, theme) => {
        Vuetify.framework.theme.dark = theme === 'dark'
        state.globalTheme = theme
    },
    setRTL: (state, isRTL) => {
        Vuetify.framework.rtl = isRTL
        state.isRTL = isRTL
    },

    setLoading(state, loading) {
        state.loading = loading;
    },

    setDrawer(state, drawer) {
        state.drawer = drawer;
    },

    setDrawerImage(state, drawerImage) {
        state.drawerImage = drawerImage;
    },

    setMini(state, mini) {
        state.mini = mini;
    },

    setToast(state, toast) {
        state.toast = toast;
    },

    setLoggedIn(state, loggedIn) {
    state.loggedIn= loggedIn
    },

    setLoggedInUser(state,loggedInUser) {
      state.loggedInUser= loggedInUser
    },

    setToken(state,token) {
        state.token = token
    },

    setManageAppLoadingStatusDialog(state, status) {
        state.manageAppLoadingStatusDialog = status;
    },
}