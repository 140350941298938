import {LicenceHttp} from "@/http/LicenceHttp";

export default {

    async setLicences({commit},personId) {
            const data = await LicenceHttp.getAllLicencesOfPerson(personId).then(result => result.data);
            commit('setLicences', data);
    },

    async setActiveLicence({commit},activeLicence) {
        commit('setActiveLicence', activeLicence);
    },
    async setAllLicenceEndorsementTypes({commit}) {
        const data = await LicenceHttp.getAllLicenceEndorsementTypes().then(result => result.data)
        commit('setAllLicenceEndorsementTypes', data);
    },

}