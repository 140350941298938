export class RoleHttp {
    /**
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAircrewRoles() {
        try {
            return await window.axios.get(`roles`)
        } catch (err) {
            return Promise.reject(err);
        }
    }
}