<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">{{ config.product.name }}</div>
      <h2 class="text-h3 text-lg-h2 mb-6">Login</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-card-text>
        <v-text-field label="Email" outlined v-model="username" :rules="[rules.required,rules.email]"></v-text-field>
        <v-text-field label="Password" outlined hide-details
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"></v-text-field>
        <!--      <v-checkbox dense label="Remember me"></v-checkbox>-->
        <v-btn block class="secondary" x-large :loading="isLoading" :disabled="isLoading || !formComplete" :rules="[rules.required]" @click="doLogin">Sign In</v-btn>
        <div class="text-center mt-2 text-body-2">
          <router-link to="/forgot">Forgot your password?</router-link>
        </div>

      </v-card-text>
    </v-card>
    <div class="text-overline text-uppercase mt-3 text-center">
      By signing in, you agree to the
      <a href="/terms">Terms of Service</a> & <a href="/privacy">Privacy Policy</a>
    </div>
  </v-container>
</template>

<script>
import config from '@/configs'
import {AuthHttp} from "@/http/AuthHttp";
import {PersonHttp} from "@/http/PersonHttp";
import {mapMutations, mapActions, mapState} from "vuex";
import jwt from 'jsonwebtoken';

export default {
  name: "LoginForm",
  data() {
    return {
      config,
      username: '',
      password: '',
      isLoading: false,
      showPassword:false,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  computed: {
    ...mapState("app",["loggedInUser"]),
    formComplete() {
      return this.username && this.password.length > 8
    }
  },
  methods: {
    ...mapActions("group",["setGroups"]),
    ...mapActions("person",["setPersons","setLoggedInPerson"]),
    ...mapMutations("app", ["setToast", "setLoggedIn", "setToken","setLoggedInUser"]),
    ...mapActions("user",["setAccountRoles", "setFlyingStatus","setGroupMembership"]),
    async doLogin() {
      if (this.username.length > 0 && this.password.length > 0) {
        this.isLoading = true;
        try {
          const res = await AuthHttp.login(this.username.toLowerCase(), this.password);

          // get the decoded payload ignoring signature, no secretOrPrivateKey needed
          const decoded = jwt.decode(res.token, {complete: true});
          await this.setLoggedIn(true);
          await this.setToken(res.token);
          await this.setGroups();

          const person = await PersonHttp.getPersonsDetailsByUserId(decoded.payload._id)

          decoded.payload.person = person.data;

          // console.log(decoded.payload._id)
          await this.setAccountRoles(decoded.payload.roles)
          await this.setLoggedInUser(decoded.payload)
          await this.setLoggedInPerson()
          await this.setGroupMembership(person.data.groupMembership)
          await this.setFlyingStatus()
          await this.setToast({
            color: "info",
            text: `Welcome ${decoded.payload.name} to your ${config.product.name} account.`,
            show: true
          });
          if (res.passwordExpired) {
            await this.$router.replace('/user/change-password');
          } else {
            await this.$router.replace('/dashboard');
          }

        } catch (e) {
          if (e.response) {
            this.setToast({color: 'error', text: e.response.data, show: true})
          } else {
            this.setToast({color: 'error', text: e.message, show: true})
          }
        } finally {
          this.isLoading = false;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>