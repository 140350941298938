export default {
    setDrawer({commit}, status) {
        commit('setDrawer', status);
    },

    setDrawerImage({commit}, status) {
        commit('setDrawerImage', status);
    },

    setLoading({commit}, loading) {
        commit('setLoading', loading);
    },

    setMini({commit}, status) {
        commit('setMini', status);
    },

    setLoggedIn({commit}, status) {
        commit('setLoggedIn', status);
    },
    setToken({commit}, token) {
        commit('setToken', token);
    },
    setLoggedInUser({commit}, data) {
        commit('setLoggedInUser', data);
    },
    setToast({commit}, toast) {
        commit('setToast', toast);
    },
    setManageAppLoadingStatusDialog({commit}, status) {
        commit('setManageAppLoadingStatusDialog', status);
    },
}