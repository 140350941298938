export class GroupSessionsHttp {

    /**
     * Returns a list of flying groups in the Database depending on status
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */

    static async getCurrentSessionStatus(groupIds) {
        try {
            return await window.axios.post(`v2/group-session/status`,groupIds)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async createNewSessionStatus(sessionStatus) {
        try {
            return await window.axios.post(`v2/group-session`,sessionStatus)
        } catch (err) {
            return Promise.reject(err);
        }
    }


}