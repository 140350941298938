import {GroupHttp} from "../../../http/GroupHttp";

export default {
    /**
     *
     * @param commit
     */
    async setGroups({commit}) {
        const data = await GroupHttp.getAll().then(result => result.data)
        commit('setGroups', data);
    },

    setActiveGroup({commit}, activeGroup) {
        commit('setActiveGroup', activeGroup);
    },



}