<template>
  <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
  >
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn
              icon
              v-on="{ ...onMenu, ...onTooltip }">
            <v-icon>mdi-link-variant</v-icon>
          </v-btn>
        </template>
        <span>External Links</span>
      </v-tooltip>
    </template>
    <v-sheet>
      <v-alert type="warning" class="mb-n3">External Links visited at users own risk.</v-alert>
      <v-list dense v-for="(item,i) in localItems" :key="i">
        <v-subheader v-if="!item.url">{{ item.name }}</v-subheader>
        <v-divider v-if="!item.url"/>
        <v-list-item v-else @click="openLink(item.url);">
          <v-list-item-title>
            <div>{{ item.name }}</div>
            <div class="text caption">{{ item.url }}</div>
          </v-list-item-title>
          <v-list-item-action>
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
import {isArray} from "lodash";

export default {
  name: "ExternalSiteLinksWidget",
  props: {
    items:{
      type:Array
    }
  },
  computed:{
    localItems(){
      return isArray(this.items) ? this.items : []
    }
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    }
  }
}
</script>

<style scoped>

</style>