export class PersonHttp {

    /**
     *
     * @param person
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async createNewPerson(person) {
        try {
            return await window.axios.post(`person`, person);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     * Get all Sorties By Group
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAllPersonsOfGroup(id) {
        try {
            return await window.axios.get(`person/group/${id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async getLoggedInPerson() {
        try {
            return await window.axios.get(`person/user`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async getFlyingStatus() {
        try {
            return await window.axios.get(`person/flying-status`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Get Persons that are part of groups that the perso belongs to
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getScopedPersons(id) {
        try {
            return await window.axios.get(`person/all/${id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Get all Sorties By Group
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getPersonsDetailsByUserId(userId) {
        try {
            return await window.axios.get(`person/single/user/${userId}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }



    /**
     * Get all Sorties By Group
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getPersonsDetailsById(Id) {
        try {
            return await window.axios.get(`person/single/${Id}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     *
     * @param person
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async updatePersonFlightCheckerStatus(Id) {
        try {
            return await window.axios.put(`person/${Id}/checkerstatus`);
        } catch (e) {
            return Promise.reject(e);
        }
    }


    /**
     *
     * @param person
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async updatePerson(person) {
        try {
            return await window.axios.put(`person`, person);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param id
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */

    static async updatePersonDetails(id,data) {
        try {
            return await window.axios.patch(`person/${id}`,data)
        } catch (err) {
            return Promise.reject(err);
        }
    }

}