<template>
  <v-container
      class="fill-height"
      fluid
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
          sm="8"
          md="4"
      >
        <v-card class="elevation-12 justify-center" max-width="600px">
          <v-toolbar>
            <v-toolbar-title class="pa-8">Reset Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text v-if="!resetPasswordRequest">
            <v-layout row wrap class="pa-8">
              <v-flex xs12>If you have forgotten your password, click <strong>continue</strong>.</v-flex>
              <v-flex xs12>This will generate a new, temporary password for your account. After you have used this
                temporary password to log in, you can create your own password
              </v-flex>
            </v-layout>
            <v-form>
              <v-text-field readonly v-show="false" :value="this.$route.query.token"/>
            </v-form>
          </v-card-text>
          <v-card-text v-if="resetPasswordRequest">
            Your temporary password is:
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ temporaryPassword }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="canCopy">
                <v-btn text @click="copyToClipboard(temporaryPassword)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
            Please copy the password and login, after which you will be asked to set a new password.
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn v-if="!resetPasswordRequest" @click="resetPassword()" color="primary">Continue
            </v-btn>
            <v-btn v-if="resetPasswordRequest" to="/" color="primary">Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapMutations} from "vuex";
import {UserHttp} from "@/http/UserHttp";

export default {
  name: "VerifyView",
  data() {
    return {
      temporaryPassword: '',
      resetPasswordRequest: false,
      isLoading: false,
      canCopy: false
    }
  },
  created() {
    this.canCopy = !!navigator.clipboard;
  },
  methods: {
    ...mapMutations("app", ["setToast", "setLoggedIn", "setToken", "setLoggedInUser"]),
    async resetPassword() {
      this.isLoading = true;
      try {
        this.temporaryPassword = await UserHttp.getTemporaryPassword(this.$route.query.token)
        this.resetPasswordRequest = true
      } catch (e) {
        if (e.response) {
          this.setToast({color: 'error', text: e.response.data, show: true})
        } else {
          this.setToast({color: 'error', text: e.message, show: true})
        }
      } finally {
        this.isLoading = false;
      }
    },
    async copyToClipboard(s) {
      await navigator.clipboard.writeText(s);
      this.setToast({color: 'success', text: 'password copied to clipboard', show: true})
    }

  }
}
</script>

<style scoped>

</style>