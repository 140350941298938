<template>
  <v-container
      class="fill-height"
      fluid
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
          sm="8"
          md="4"
      >
        <change-password/>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>


import ChangePassword from "@/components/shared/ChangePassword";
export default {
  name: "ChangePasswordView",
  components: {ChangePassword},
}
</script>

<style scoped>

</style>