export default {

    setEvents(state, events) {
        state.events = events;
    },

    setActiveCalendarEvent(state, activeCalendarEvent) {
        state.activeCalendarEvent = activeCalendarEvent;
    },
    setManageCalendarEventDialog(state, status) {
        state.manageCalendarEventDialog = status;
    },
    setManageCalendarFilterDialog(state, status) {
        state.manageCalendarFilterDialog = status;
    },
}